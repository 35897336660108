import React, { useEffect } from 'react';

// Routing
import { Routes, Route } from 'react-router-dom'

// Context
// import { AppContext } from "./lib/contextLib";

// Componentes
import AuthUser from './components/AuthUser';

// Vistas
import { LandingPage } from './views/LandingPage'
import { Login } from './views/Login'
import { Register } from './views/Register';

// Catalogo
import { CatalogoPortada } from './views/Catalogo/CatalogoPortada';
import { CatalogoVert } from './views/Catalogo/CatalogoVert';
import { CatalogoIngco } from './views/Catalogo/CatalogoIngco';
import { CatalogoWadfow } from './views/Catalogo/CatalogoWadfow';
import { CatalogoCorona } from './views/Catalogo/CatalogoCorona';
import { CatalogoFleximatic } from './views/Catalogo/CatalogoFleximatic';
import { CatalogoQuilosa } from './views/Catalogo/CatalogoQuilosa';
import { CatalogoImou } from './views/Catalogo/CatalogoImou';

// Producto Catalogo
import { VertProduct } from './views/Catalogo/DetalleProductos/VertProduct';

// Vendedor
import { Dashboard } from './views/Dashboard';
import { ConsultaCliente } from './views/Clientes/ConsultaCliente';
import { ConsultaPedido } from './views/Vendedor/Pedidos/ConsultaPedido';
import { ConsultaFactura } from './views/Vendedor/Facturas/ConsultaFactura';
import { ConsultaTracking } from './views/Vendedor/Tracking/ConsultaTracking';
import { ConsultaCobranzas } from './views/Vendedor/Cobranzas/ConsultaCobranzas';
import { ConsultaManifiesto } from './views/Vendedor/Manifiesto/ConsultaManifiesto';

// Supervisor
import { DashboardSupervisor } from './views/Supervisor/DashboardSupervisor';
import { Metas } from './views/Supervisor/Metas';
import { Estadisticas } from './views/Supervisor/Estadisticas';
import { EstadisticasGerente } from './views/Supervisor/EstadisticasGerente';
import { ConsultaTrackingSupervisor } from './views/Supervisor/Tracking/ConsultaTrackingSupervisor';
import { MetasSemanales } from './views/Supervisor/MetasSemanales';
import { Estimaciones } from './views/Supervisor/Estimaciones'

import { ConsultaPedidosVendedor } from './views/Supervisor/GestionVendedores/Pedidos/ConsultaPedidosVendedor';
import { ConsultaFacturasVendedor } from './views/Supervisor/GestionVendedores/Facturas/ConsultaFacturasVendedor';

import { GestionVendedor } from './views/Supervisor/GestionVendedores/GestionVendedor';
import { ConsultaManifiestoSupervisor } from './views/Supervisor/Manifiesto/ConsultaManifiestoSupervisor';
// Gerente
import { DashboardGerente } from './views/Supervisor/DashboardGerente';

// Cobranzas
import { InfoCobranza } from './views/Supervisor/Cobranzas/InfoCobranzaZona';
import { MasterCobranzas } from './views/Supervisor/Cobranzas/MasterCobranzas';

// Exhibidores
import { AnalisisCliente } from './views/Exhibidores/AnalisisCliente';
import { FormularioExhibidor } from './views/Exhibidores/FormularioExhibidor';
import { Buzon } from './views/Exhibidores/Buzon';
import { BuzonPreSolicitudes } from './views/Exhibidores/BuzonPreSolicitudes';

// Presupuestos
import { Presupuesto } from './views/Vendedor/Presupuestos/Presupuesto';
import { ConsultaPresupuesto } from './views/Vendedor/Presupuestos/ConsultaPresupuesto';
import { ModificarPresupuesto } from './views/Vendedor/Presupuestos/ModificarPresupuesto';

// Pedidos Sugeridos
// import { PedidosSugerido } from './views/Vendedor/PedidosSugeridos/PedidosSugeridos';

// Consulta de productos y clientes
import { ConsultaProductos } from './views/Vendedor/Productos/ConsultaProductos';
import { Articulos } from './views/Vendedor/Productos/Articulos';

// PARA GENERAR LAS LISTAS DE PRECIO
import { PriceListView } from './views/XLS/PriceListView';
import { PriceListView2 } from './views/XLS/PriceListViewVendedor';
import { ListViewTop100 } from './views/XLS/ListViewTop100';

import { PageNotFound } from './views/404/PageNotFound';
import { Redirect } from './views/Redirect';

import { FormularioImagen } from './views/FormularioImagen';

// Página web cerrada
import { PageClosed } from './views/PageClosed'

// Noticias
// import { NoticiaEvento } from './views/Noticias/NoticiaEvento';
// import { NoticiaVert } from './views/Noticias/NoticiaVert';
// import { NoticiaIngco } from './views/Noticias/NoticiaIngco';

// Tutoriales
import { CrearTutorial } from './views/Vendedor/Tutoriales/CrearTutorial';
import { Tutorial } from './views/Vendedor/Tutoriales/Tutorial';

// Estilos
import './index.css';
import './assets/scss/App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'rsuite/dist/rsuite.min.css'
import { Vert } from './views/PDF/CatalogoPDF/Vert';
import { PresupuestoLubricante } from './views/Vendedor/Presupuestos/PresupuestoLubricante';
import { ModificarPresupuestoLubricante } from './views/Vendedor/Presupuestos/ModificarPresupuestoLubricante';

import Button from '../src/components/ButtonTop'

function App() {

  // State del usuario
  const { getToken } = AuthUser();

  useEffect(() => {
  }, []);

  // Function to clear complete cache data
  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //     });
  //   });
  //   alert('Complete Cache Cleared')
  // };

  return (
    <>
      <Button/>
      <Routes>
        {
          (!getToken()) ?
            <>
              {/* Página de inicio */}
              <Route path="/" element={<LandingPage />} />

              {/* Catálogos */}
              <Route path="/catalogo-portada" element={<CatalogoPortada />} />
              <Route path="/catalogo-vert" element={<CatalogoVert />} />
              <Route path="/catalogo-ingco" element={<CatalogoIngco />} />
              <Route path="/catalogo-wadfow" element={<CatalogoWadfow />} />
              <Route path="/catalogo-corona" element={<CatalogoCorona />} />
              <Route path="/catalogo-fleximatic" element={<CatalogoFleximatic />} />
              <Route path="/catalogo-quilosa" element={<CatalogoQuilosa />} />
              <Route path="/catalogo-imou" element={<CatalogoImou />} />

              {/* Detalles de productos en catálogo */}
              <Route path="/catalogo-vert/products/:codigo" element={<VertProduct />} />
              <Route path="/catalogo-ingco/products/:codigo" element={<CatalogoIngco />} />
              <Route path="/catalogo-wadfow/products/:codigo" element={<CatalogoWadfow />} />
              <Route path="/catalogo-corona/products/:codigo" element={<CatalogoCorona />} />
              <Route path="/catalogo-fleximatic/products/:codigo" element={<CatalogoFleximatic />} />
              <Route path="/catalogo-quilosa/products/:codigo" element={<CatalogoQuilosa />} />
              <Route path="/catalogo-imou/products/:codigo" element={<CatalogoImou />} />

              <Route path="/redirectUser" element={<Redirect />} />

              {/* Página de inicio de sesión */}
              <Route path="/login" element={<Login />}></Route>

              {/* Apartado de noticias */}
              {/* <Route path="/noticia-evento" element={<NoticiaEvento />}></Route>
              <Route path="/noticia-vert" element={<NoticiaVert />}></Route>
              <Route path="/noticia-ingco" element={<NoticiaIngco />}></Route> */}

              {/* Prueba subir foto */}
              {/* <Route path="/subir-foto" element={<FormularioImagen />}></Route> */}

              {/* En caso de no encontrar ningúna ruta de las especificadas*/}
              <Route path="/*" element={
                <PageNotFound></PageNotFound>
              }></Route>
            </> :
            <>
              {/* ACTIVAR EN CASO DE QUE LA PAGINA SE NECESITE CERRAR */}
              <Route path="/closed" element={<PageClosed />} />

              {/* Página de inicio */}
              <Route path="/" element={<LandingPage />} />

              {/* Catálogos */}
              <Route path="/catalogo-portada" element={<CatalogoPortada />} />
              <Route path="/catalogo-vert" element={<CatalogoVert />} />
              <Route path="/catalogo-ingco" element={<CatalogoIngco />} />
              <Route path="/catalogo-wadfow" element={<CatalogoWadfow />} />
              <Route path="/catalogo-corona" element={<CatalogoCorona />} />
              <Route path="/catalogo-fleximatic" element={<CatalogoFleximatic />} />
              <Route path="/catalogo-quilosa" element={<CatalogoQuilosa />} />
              <Route path="/catalogo-imou" element={<CatalogoImou />} />

              {/* Detalles de productos en catálogo */}
              <Route path="/catalogo-vert/products/:codigo" element={<VertProduct />} />
              <Route path="/catalogo-ingco/products/:codigo" element={<CatalogoIngco />} />
              <Route path="/catalogo-wadfow/products/:codigo" element={<CatalogoWadfow />} />
              <Route path="/catalogo-corona/products/:codigo" element={<CatalogoCorona />} />
              <Route path="/catalogo-fleximatic/products/:codigo" element={<CatalogoFleximatic />} />
              <Route path="/catalogo-quilosa/products/:codigo" element={<CatalogoQuilosa />} />
              <Route path="/catalogo-imou/products/:codigo" element={<CatalogoImou />} />

              {/* Tutorial */}
              <Route path="/register-videos" element={<CrearTutorial />} />
              <Route path="/tutorials" element={<Tutorial />} />

              <Route path="/redirectUser" element={<Redirect />} />

              {/* Página de panel de supervisor */}
              <Route path="/dashboard-supervisor" element={<DashboardSupervisor />}></Route>
              <Route path="/orders/:codigo" element={<ConsultaPedidosVendedor />}></Route>
              <Route path="/invoices/:codigo" element={<ConsultaFacturasVendedor />}></Route>
              <Route path="/seller-management" element={<GestionVendedor />}></Route>
              <Route path="/manifiesto-supervisor" element={<ConsultaManifiestoSupervisor />}></Route>

              {/* Página de panel de gerente */}
              <Route path="/dashboard-gerente" element={<DashboardGerente />}></Route>

              {/* Página para asignar las metas de los vendedores */}
              <Route path="/goals" element={<Metas />}></Route>

              {/* Página para visualizar las estimaciones de cobranzas */}
              <Route path="/appraisals" element={<Estimaciones />}></Route>

              {/* Página para ver las estadisticas */}
              <Route path="/zone-stats" element={<Estadisticas />}></Route>
              <Route path="/general-stats" element={<EstadisticasGerente />}></Route>

              {/* Página para ver las metas semanales de los vendedores (SUPERVISOR) */}
              <Route path="/metas-vendedores" element={<MetasSemanales />}></Route>

              {/* Página para ver las estadisticas */}
              <Route path="/cobranzas-por-zona" element={<InfoCobranza />}></Route>

              {/* Página del master de cobranzas */}
              <Route path="/ventas-vs-pagado" element={<MasterCobranzas />}></Route>

              {/* Página de panel de vendedor */}
              <Route path="/dashboard" element={<Dashboard />}></Route>

              {/* Rutas de módulos del panel de vendedor */}
              <Route path="/orders" element={<ConsultaPedido />}></Route>
              <Route path="/invoices" element={<ConsultaFactura />}></Route>
              <Route path="/clients" element={<ConsultaCliente />}></Route>
              <Route path="/tracking" element={<ConsultaTracking />}></Route>
              <Route path="/payments" element={<ConsultaCobranzas />}></Route>
              <Route path="/cargo-manifest" element={<ConsultaManifiesto />}></Route>

              {/* <Route path="/pedidos-sugeridos" element={<PedidosSugerido />}></Route> */}

              {/* Página para registrar nuevos usuarios */}
              <Route path="/register" element={<Register />}></Route>

              {/* Página para las solicitudes de exhibidores y su buzón de mensajes */}
              <Route path="/exhibidores/presolicitud" element={<AnalisisCliente />}></Route>
              <Route path="/exhibidores/solicitud-exhibidores" element={<FormularioExhibidor />}></Route>
              <Route path="/exhibidores/buzon/presolicitudes" element={<BuzonPreSolicitudes />}></Route>
              <Route path="/exhibidores/buzon/solicitudes" element={<Buzon />}></Route>

              {/* Página para armar presupuestos */}
              <Route path="/make-preorders" element={<Presupuesto />}></Route>
              <Route path="/make-preorders2" element={<PresupuestoLubricante />}></Route>
              <Route path="/preorders" element={<ConsultaPresupuesto />}></Route>
              <Route path="/preorders/modify/:documento" element={<ModificarPresupuesto />}></Route>
              <Route path="/preorders/modify2/:documento" element={<ModificarPresupuestoLubricante />}></Route>

              {/* Página para consultar pedidos sugeridos */}
              {/* <Route path="/consulta-sugeridos" element={<PedidosSugerido />}></Route> */}

              <Route path="/subir-foto" element={<FormularioImagen />}></Route>

              {/* Página para consultar tracking en operaciones */}
              <Route path="/operations/tracking" element={<ConsultaTrackingSupervisor />}></Route>

              {/* Página para consultar tracking en operaciones */}
              <Route path="/products" element={<ConsultaProductos />}></Route>
              <Route path="/articles" element={<Articulos />}></Route>

              {/* Página para generar las listas de precio de cadenas */}
              <Route path="/price-list" element={<PriceListView />}></Route>
              <Route path="/price-list2" element={<PriceListView2 />}></Route>
              <Route path="/top-list" element={<ListViewTop100/>}></Route>
              
              <Route path="/pdf-vert" element={<Vert />} />

              {/* En caso de no encontrar ningúna ruta de las especificadas*/}
              <Route path="/*" element={
                <PageNotFound></PageNotFound>
              }></Route>
            </>
        }
      </Routes>
    </>
  );
}

export default App;
