import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthUser } from './AuthUser';
import { menuDataVendedor, menuDataSupervisor, menuDataVideo, menuDataGerente, menuDataVendedorLubricante } from '../data/menuData';

import belmenyLogo2 from '../assets/img/logoBG.webp';
import { MdHome } from "react-icons/md";

import "../assets/css/navbar.css"

export const NavbarComponent = (props) => {

  const [authButton, setAuthButton] = useState()
  const [dashboardButton, setDashboardButton] = useState()
  const [catalogueButton, setCatalogueButton] = useState()
  const [modules, setModules] = useState()
  const [bgBtnApplied, setBgBtnApplied] = useState('btn-hover')
  const [bgHeaderApplied, setBgHeaderApplied] = useState('bg-header')

  const navigate = useNavigate();
  const { getToken } = AuthUser();

  const { token, logout, user } = AuthUser();

  const logoutUser = () => {
    if (token !== undefined) {
      logout();
      navigate('/login');
    }
  }
  //tipos de perfil segun lo que seleccione el boton
  const getDashboardButton = (user) => {
    const buttonConfigs = {
      default: {
        href: '/',
        text: <MdHome />,
      },
      vendedor: {
        href: '/dashboard',
        text: 'Inicio',
      },
      supervisor: {
        href: '/dashboard-supervisor',
        text: 'Inicio',
      },
      gerente: {
        href: '/dashboard-gerente',
        text: 'Inicio',
      },
      master: {
        href: '/register',
        text: 'Inicio',
      },
      consulta: {
        href: '/consulta-productos',
        text: 'Inicio',
      },
      rrss: {
        href: '/tutorials',
        text: 'Inicio',
      },
      cadenas: {
        href: '/price-list',
        text: 'Inicio',
      },
      cobranzas: {
        href: '/appraisals',
        text: 'Inicio',
      },
    }

    let buttonConfig;
    //lista para el boton de inicio dependiendo del usuario
    if (!user) {
      buttonConfig = buttonConfigs.default;
    } else if (user.CodVendedor !== null && user.CodSupervisor !== null) {
      buttonConfig = buttonConfigs.vendedor;
    } else if (user.CodSupervisor === 'CONSULTA') {
      buttonConfig = buttonConfigs.consulta;
    } else if (user.CodSupervisor !== null && user.CodGerente === null) {
      buttonConfig = buttonConfigs.rrss;
    } else if (user.CodGerente === 'CADENASBG') {
      buttonConfig = buttonConfigs.cadenas;
    } else if (user.CodGerente === 'COBRANZASBG') {
      buttonConfig = buttonConfigs.cobranzas;
    } else if (user.CodGerente === 'master') {
      buttonConfig = buttonConfigs.master;
    } else if (user.CodVendedor !== null && user.CodGerente !== null) {
      buttonConfig = buttonConfigs.supervisor;
    } else if (user.CodSupervisor === null && user.CodGerente !== null) {
      buttonConfig = buttonConfigs.gerente;
    }

    return (
      <Nav.Link href={buttonConfig.href} className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>
        {buttonConfig.text}
      </Nav.Link>
    )
  }

  const getModules = (user) => {
    if (user.CodVendedor !== null && user.CodSupervisor !== null && user.VendeLubricante !== 1) {
      setModules(menuDataVendedor)
    } else if (user.CodVendedor !== null && user.CodSupervisor !== null && user.VendeLubricante === 1) {
      setModules(menuDataVendedorLubricante)
    } else if (user.CodVendedor !== null && user.CodGerente !== null) {
      setModules(menuDataSupervisor)
    } else if (user.CodSupervisor === 'SSAAB') {
      setModules(menuDataVideo)
    } else if (user.CodVendedor === null && user.CodSupervisor === null && user.CodGerente !== null) {
      setModules(menuDataGerente)
    }
  }

  const loadNavBar = () => {
    switch (props.background) {
      case 'vert':
        setBgHeaderApplied('bg-header')
        setBgBtnApplied('btn-hover')
        break;

      case 'ingco':
        setBgHeaderApplied('bg-headerIngco')
        setBgBtnApplied('btn-hoverIngco')
        break;

      case 'wadfow':
        setBgHeaderApplied('bg-headerWadfow')
        setBgBtnApplied('btn-hoverWadfow')
        break;

      case 'corona':
        setBgHeaderApplied('bg-headerCorona')
        setBgBtnApplied('btn-hoverCorona')
        break;

      case 'fleximatic':
        setBgHeaderApplied('bg-headerFleximatic')
        setBgBtnApplied('btn-hoverFleximatic')
        break;

      case 'quilosa':
        setBgHeaderApplied('bg-headerQuilosa')
        setBgBtnApplied('btn-hoverQuilosa')
        break;

      case 'imou':
        setBgHeaderApplied('bg-headerImou')
        setBgBtnApplied('btn-hoverImou')
        break;


      default:
        setBgHeaderApplied('bg-header')
        setBgBtnApplied('btn-hover')
        break;
    }

    if (!getToken()) {
      setDashboardButton(getDashboardButton(null))
      setAuthButton(
        <Nav.Link href="/login" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>
          Iniciar sesión
        </Nav.Link>
      )
      setCatalogueButton(
        <Nav.Link href="/catalogo-portada" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>
          Catálogo Productos
        </Nav.Link>
      )
    } else {
      setDashboardButton(getDashboardButton(user))
      setAuthButton(
        <Nav.Link onClick={logoutUser} href="/" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>
          Cerrar sesión
        </Nav.Link>
      )
      if (user.CodGerente === 'HABOULMOUNA') {
        setCatalogueButton(
          <Nav.Link href="/catalogo-portada" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>
            Catálogo Productos
          </Nav.Link>
        )
      }

      getModules(user)
    }
  }

  useEffect(() => {
    loadNavBar()
  }, [])

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-belmeny3">
      <Container fluid>
        <Navbar.Brand href="#home">
          <img src={belmenyLogo2} alt="Belmeny Logo" className='text-center drop-shadow-sm align-center ms-4 w-25' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='text-white' />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            {dashboardButton}
          </Nav>
          <Nav>
            {
              (user.CodVendedor === 'V138' || user.CodVendedor === 'V67') ?
                <Nav.Link href="/price-list" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0 ms-2`}>Lista de Precios</Nav.Link>
                : null
            }
          </Nav>
          <Nav className="me-auto">
            <NavDropdown title={
              <span className={`text-decoration-none btn ${bgBtnApplied} border-0`}>
                Módulos
              </span>
            } id="mega-dropdown" className="me-3">
              <div className="p-2 dropdown-menu-inner">
                {modules}
              </div>
            </NavDropdown>
          </Nav>
          <Nav>
            {authButton}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
