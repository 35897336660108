
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';

import AuthUser from '../../components/AuthUser';

import { ClientesSupervisorXLS } from '../XLS/ClientesSupervisorXLS';

import { getVendedor, RequestPage, getPorcentajeClientes2, getPorcentajeVendedor } from '../../api/request';
import { getVentasClientes, getDetalleClientesAtendidosxVendedor, getClientesDesatendidosByVendedor, getClientes, getClientePorRif,getVentasClienteIngco,getVentasClienteVert } from '../../api/requestClientes';

import { format, set } from 'date-fns';

// import 'chart.js/auto';
// import { Chart } from 'react-chartjs-2';

import Chart from "react-apexcharts";

import { RiCloseCircleFill } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";

import Pagination from 'react-responsive-pagination';

export const DetalleCliente2 = (props) => {

  var Clientes = props.item
  var firstDay = props.fechaInicio
  var lastDay = props.fechaFin

  // State del usuario
  const { user } = AuthUser();

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [showDetalleCliente, setShowDetalleCliente] = useState(false);

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [gerente, setGerente] = useState(user.CodGerente);
  const [modulo, setModulo] = useState('');
  const [chartData, setChartData] = useState({});
  const [chartData2, setChartData2] = useState({});
  const [vendedorInfo, setVendedorInfo] = useState([])
  const [clientesAttData, setClientesAttData] = useState([]);
  const [clienteData, setClienteData] = useState({})

  // Paginación
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [porcentajeVendedor, setPorcentajeVendedor] = useState({});

  const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  const getArrayDiff = (data) => {
    var datosArr = [
      {
        months: 'ENERO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'FEBRERO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'MARZO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'ABRIL',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'MAYO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'JUNIO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'JULIO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'AGOSTO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'SEPTIEMBRE',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'OCTUBRE',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'NOVIEMBRE',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'DICIEMBRE',
        ventas: 0,
        year: data[0].year,
      }
    ]

    const completeArray = datosArr.map(monthObj => {
      const matchingData = data.find(dataObj => dataObj.months === monthObj.months && dataObj.year === monthObj.year);
      return { ...monthObj, ventas: matchingData?.ventas_ingco || 0 };
    });

    return completeArray;
  }

  const getArrayDiff2 = (data) => {
    var datosArr = [
      {
        months: 'ENERO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'FEBRERO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'MARZO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'ABRIL',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'MAYO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'JUNIO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'JULIO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'AGOSTO',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'SEPTIEMBRE',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'OCTUBRE',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'NOVIEMBRE',
        ventas: 0,
        year: data[0].year,
      },
      {
        months: 'DICIEMBRE',
        ventas: 0,
        year: data[0].year,
      }
    ]

    const completeArray = datosArr.map(monthObj => {
      const matchingData = data.find(dataObj => dataObj.months === monthObj.months && dataObj.year === monthObj.year);
      return { ...monthObj, ventas: matchingData?.ventas_vert || 0 };
    });

    return completeArray;
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    switch (modulo) {
      case 'activos':
        loadPage(urlPage.replace(/.$/, page + "&Vendedor=" + Clientes.Vendedor))
        break;

      case 'desatendidos':
        loadPage(urlPage.replace(/.$/, page + "&loadDesatendidos=General&CodigoVendedor=" + Clientes.Vendedor))
        break;
      default:
        break;
    }
    // http://127.0.0.1:8000/api/getClientesDesatendidosByVendedor?CodigoVendedor=V26&loadDesatendidos=General
  }

  const loadChart = async (CodigoCliente) => {
    setLoading(true)
    if (user.CodVendedor !== null && user.CodSupervisor !== null && user.CodGerente === null) {
      const clientData = await getClientePorRif(CodigoCliente, user.CodVendedor)
      setClienteData(clientData)
    }

    const infoVendedor = await getVendedor(user.CodVendedor);
    setVendedorInfo(infoVendedor[0].Nombre)

    
    const data = await getVentasClienteIngco('2025', CodigoCliente)
    const data2 = await getVentasClienteVert('2025', CodigoCliente)

    
    const arr = getArrayDiff(data)
    const arr2 = getArrayDiff2(data2)

    
    sortByMonth(arr)

    setChartData({
      options: {
        chart: {
          id: "chart-VentasAnuales",
          type: "area"
        },
        xaxis: {
          categories: labels
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        colors: ['#fba10b'],
        dataLabels: {
          enabled: false
        },
      },
      series: [
        {
          name: 'Ventas del año 2025 del Top 100 Ingco en % ',
          data: arr.map((Ventas) => Ventas.ventas)
        }
      ]
    })

    sortByMonth(arr2)

    setChartData2({
      options: {
        chart: {
          id: "chart-VentasAnuales",
          type: "area"
        },
        xaxis: {
          categories: labels
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        colors: ['#0041ff'],
        dataLabels: {
          enabled: false
        },
      },
      series: [
        {
          name: 'Ventas del año 2025 del Top 100 Vert en % ',
          data: arr2.map((Ventas) => Ventas.ventas)
        }
      ]
    })

    setLoading(false)
  }

  const loadClientesVendedor = async (whichToLoad) => {
    setLoading(true)
    switch (whichToLoad) {
      case 'activos':
        setModulo('activos')
        const resActivos = await getClientes(Clientes.Vendedor)

        setClientesAttData(resActivos.data)
        setPage(resActivos.current_page)
        setTotalPages(resActivos.last_page)
        setUrlPage(resActivos.first_page_url)

        break;

      case 'atendidos':
        const resAtendidos = await getDetalleClientesAtendidosxVendedor(Clientes.Vendedor, firstDay, lastDay)
        setModulo('atendidos')
        setClientesAttData(resAtendidos)
        break;

      case 'desatendidos':
        setModulo('desatendidos')
        const resDesatendidos = await getClientesDesatendidosByVendedor(Clientes.Vendedor, 'General')
        setClientesAttData(resDesatendidos.data);
        setCurrentPage(resDesatendidos.current_page)
        setPage(resDesatendidos.current_page)
        setTotalPages(resDesatendidos.last_page)
        setUrlPage(resDesatendidos.first_page_url)
        break;

      default:
        setModulo('activos')
        const resActivosD = await getClientes(Clientes.Vendedor)
        setClientesAttData(resActivosD.data)
        break;
    }

    setLoading(false)
  }

  const loadPage = async (pageData) => {
    setLoading(true)
    const porcentajeClientes= await getPorcentajeClientes2(user.CodVendedor);
    setClientesAttData(porcentajeClientes.data)
    setPage(porcentajeClientes.current_page)
    setTotalPages(porcentajeClientes.last_page)
    setUrlPage(porcentajeClientes.first_page_url)

    setLoading(false)
  }

  function sortByMonth(arr) {
    var months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
      "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    arr.sort(function (a, b) {
      return months.indexOf(a.months)
        - months.indexOf(b.months);
    });
  }

  function handleShow(whichToLoad) {
    setFullscreen(true);
    setShow(true);
    if (user.CodGerente === null) {
      loadChart(Clientes.clientes);
    } else if (user.CodGerente !== null) {
      loadClientesVendedor(whichToLoad);
    }
  }

  function handleShowDetalleCliente(CodigoCliente) {
    setFullscreen(true);
    setShowDetalleCliente(true);
    loadChart(Clientes.clientes);
  }

  useEffect(() => {
  }, []);

  return (
    <>
      <div className="d-none d-md-block d-lg-block">
        {
          (user.CodGerente !== null) ?
            <div className="row py-2">
              <div className="col text-center pt-2"><span className="text-danger" key={Clientes.Vendedor}>{Clientes.Vendedor}</span></div>
              <div className="col text-center pt-2"><span className="text-belmeny">{Clientes.Nombre}</span></div>
              <div className="col text-center">
                <button className="btn btn-primary w-50" onClick={() => handleShow('activos')}>{Clientes.ClientesAct} activos</button>
              </div>
              <div className="col text-center">
                <button className="btn btn-success w-50" onClick={() => handleShow('atendidos')}>{Clientes.Atendidos} atendidos</button>
              </div>
              <div className="col text-center">
                <button className="btn btn-danger w-75" onClick={() => handleShow('desatendidos')}>{(Clientes.ClientesAct - Clientes.Atendidos)} no atendidos</button>
              </div>
              <div className="col text-center">
                <ClientesSupervisorXLS vendedor={Clientes.Vendedor} />
              </div>
            </div> :
            <div className="row py-2">
              <div className="col text-center pt-2"><span className="text-danger fw-bold" key={Clientes.Rif}>{Clientes.clientes}</span></div>
              <div className="col text-center pt-2"><span className="text-belmeny">{Clientes.Nombre}</span></div>
              <div className="col text-center pt-2"><span className="text-warning">
                {
                  (Clientes.porcentaje_ingco) 
                }%
                </span>
              </div>
              <div className="col text-center pt-2">
                {
                      <span className='text-primary fw-bold'>{Clientes.porcentaje_vert}%</span>
                }
              </div>
              <div className="col text-center pt-2">
                <button className="btn btn-success btn-sm w-75" onClick={() => handleShow()}>Ver detalles</button>
              </div>
            </div>
        }
      </div>

      <div className="container-fluid d-sm-block d-md-none">
        <div className="card my-3 drop-shadow border-belmeny">
          <div className="card-body">
            <div className="row">
              <div className="col-10">
                <h5 className="card-title text-belmeny">{Clientes.Nombre.toUpperCase()}</h5>
              </div>
              <div className="col-2">
                              <SlOptionsVertical className='text-belmeny fs-5 float-end' style={{ cursor: 'pointer' }} onClick={() => handleShow()} />
                            </div>
              <div className="col-2">
               
              </div>
            </div>
            {
              (user.CodGerente !== null) ?
                <>
                  <h6 className="card-subtitle mb-2 text-muted fst-italic">{Clientes.Vendedor}</h6>
                  <h6><span className="text-belmeny fw-bold">{Clientes.ClientesAct}</span> <span className="fw-normal"> clientes activos</span> <br />
                    <span className="text-success fw-bold">{Clientes.Atendidos}</span> <span className="fw-normal"> clientes atendidos</span> <br />
                    <span className="text-danger fw-bold">{(Clientes.ClientesAct - Clientes.Atendidos)}</span> <span className="fw-normal"> clientes deudores</span> <br />
                  </h6>
                </> :
                <>
                  <h6 className="card-subtitle mb-2 text-muted fst-italic">{Clientes.clientes}</h6>
                  {
                      <h6>Porcentaje INGCO: <span className="fw-bold text-warning">{Clientes.porcentaje_ingco}%</span></h6>
                  }
                  {
                      <h6>Porcentaje Vert: <span className="fw-bold text-primary">{Clientes.porcentaje_vert}%</span></h6>
                  }
                </>
            }
          </div>
        </div>
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>
            {
              (user.CodGerente !== null) ?
                <>Información de los clientes del vendedor: {Clientes.Nombre}</> :
                <>Información del cliente: {Clientes.Nombre}</>
            }
          </Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-4 bg-belmeny"></div>
        <Modal.Body>

          <div className="container-fluid">
            {/* Div para escritorio */}
            <div className='d-none d-md-block d-lg-block'>
              {
                (user.CodGerente !== null) ?
                  <>
                    {
                      (loading) ?
                        <>
                          <div className="text-center">
                            <div className="m-auto spinner">
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            </div>
                          </div>
                        </> :
                        <>
                          <h4 className="text-belmeny text-center fst-italic">Lista de Clientes {(modulo === 'activos') ? <>Activos</> : (modulo === 'atendidos') ? <>Atendidos</> : <>Deudores</>}</h4>
                          <div className="table-responsive">
                            <table className="table table-bordered table-hover border-belmeny mt-4">
                              <thead className='bg-belmeny text-light text-center pb-3'>
                                <th style={{ height: '40px' }} className='pb-2'>RIF</th>
                                <th className='pb-2'>Cliente</th>
                                <th className='pb-2'>Dirección</th>
                                {(modulo === 'activos') ? <th className='pb-2'>Fecha Última Facturación</th> : null}
                                <th className='pb-2'>Deuda Pendiente</th>
                                <th className='pb-2'>Opciones</th>
                              </thead>
                              <tbody>
                                {
                                  clientesAttData.map((item) => {
                                    return (
                                      (modulo === 'activos') ?
                                        <tr key={item.Codigo} className='fw-bold'>
                                          <td className='text-center text-danger' style={{ height: '50px' }}>{item.Codigo}</td>
                                          <td className='text-center'>{item.Nombre}</td>
                                          <td className='text-center'>{item.DireccionFiscal}</td>
                                          <td className='text-center'>{new Date(item.FechaDocumento).toLocaleDateString('es-MX', { timeZone: 'UTC' })}</td>
                                          <td className='text-center text-danger'>${item.SaldoPendiente.toFixed(2)}</td>
                                          <td className='text-center text-danger'><button className='btn btn-belmeny text-white my-2' onClick={() => handleShowDetalleCliente(item.Codigo)}>Ver detalles</button></td>
                                        </tr> : (modulo === 'atendidos') ?
                                          <tr key={item.Codigo} className='fw-bold'>
                                            <td className='text-center text-danger' style={{ height: '50px' }}>{item.Codigo}</td>
                                            <td className='text-center'>{item.Nombre}</td>
                                            <td className='text-center'>{item.DireccionFiscal}</td>
                                            <td className='text-center text-danger'>${item.SaldoPendiente.toFixed(2)}</td>
                                            <td className='text-center text-danger'><button className='btn btn-belmeny text-white my-2' onClick={() => handleShowDetalleCliente(item.Codigo)}>Ver detalles</button></td>
                                          </tr> :
                                          <tr key={item.Codigo} className='fw-bold'>
                                            <td className='text-center text-danger' style={{ height: '50px' }}>{item.Codigo}</td>
                                            <td className='text-center'>{item.Nombre}</td>
                                            <td className='text-center'>{item.DireccionFiscal}</td>
                                            <td className='text-center text-danger'>${item.SaldoPendiente.toFixed(2)}</td>
                                            <td className='text-center text-danger'><button className='btn btn-belmeny text-white my-2' onClick={() => handleShowDetalleCliente(item.Codigo)}>Ver detalles</button></td>
                                          </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>

                            <Pagination
                              total={totalPages}
                              current={currentPage}
                              onPageChange={page => handlePageChange(page)}
                            />
                          </div>
                        </>
                    }
                  </> :
                  <div className="row">
                  <div className="col">
                    <h2 className='text-center text-belmeny'>{Clientes.Nombre}</h2>
                    <div className="division w-25 m-auto my-3 bg-belmeny"></div>
                
                    <div className="text-center">
                      <h4 className='badge-vendedor rounded-pill text-light m-auto drop-shadow' style={{ width: "40%" }}>VENDEDOR ENCARGADO</h4>
                      <h3 className='bg-belmeny rounded-pill text-light fw-light m-auto drop-shadow' style={{ width: "60%" }}>{vendedorInfo}</h3>
                    </div>
                
                    {/* Inicio de la sección de charts */}
                    {
                      (loading) ?
                        <div className="text-center mt-3"> {/* Margen superior para separar del título */}
                          <div className="m-auto spinner">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </div>
                        </div> :
                        <div className="mt-3"> {/* Margen superior para separar del título */}
                          <div className="row justify-content-center">
                            <div className="col-md-6">
                            <h5 className="text-center text-belmeny mt-4">Porcentaje de Top 100 Productos Ingco Anual</h5>
                              <Chart
                                options={chartData.options}
                                series={chartData.series}
                                type="area"
                                width="100%"
                                height="450px"
                              />
                            </div>
                            <div className="col-md-6">
                            <h5 className="text-center text-belmeny mt-4">Porcentaje de Top 100 Productos Vert Anual</h5>
                              <Chart
                                options={chartData2.options}
                                series={chartData2.series}
                                type="area"
                                width="100%"
                                height="450px"
                              />
                            </div>
                          </div>
                        </div>
                    }
                    {/* Fin de la sección de charts */}
                
                  </div>
                </div>
              }
            </div>

            {/* Div para móvil */}
            <div className='d-sm-block d-md-none'>
              {
                (user.CodGerente !== null) ?
                  <>
                  </> :
                  <div className="row">
                    <div className="row">
                      <h4 className='text-center text-belmeny fst-italic'>{Clientes.Rif}</h4>
                      <h3 className='text-center text-belmeny'>{Clientes.Nombre}</h3>
                      <div className="division w-50 m-auto my-3 bg-belmeny"></div>
                      <div className="text-center">
                        <h4 className='badge-vendedor rounded-pill text-light m-auto' style={{ width: "80%", position: "relative", left: "auto", top: "15%" }}>VENDEDOR ENCARGADO</h4>
                        <h3 className='bg-belmeny rounded-pill text-light fw-light m-auto pt-3' style={{ width: "100%" }}>{vendedorInfo}</h3>
                      </div>
                    </div>
                    <ul className='mt-5'>
                      {/* <li className='client-info'>
                        <h4><b>Telefono:</b> {Clientes.Telefono1}</h4>
                      </li>
                      <li className='client-info'>
                        <h4><b>Dirección Fiscal:</b> {Clientes.DireccionFiscal}</h4>
                      </li>
                      <li className='client-info'>
                        <h4><b>Limite:</b> ${Clientes.Limite}</h4>
                      </li>
                      <li className='client-info'>
                        <h4>
                          <b>Saldo Pendiente por Cobrar:</b>
                          {
                            (Clientes.SaldoPendiente > 0) ?
                              <>
                                <span className='text-danger'>${Clientes.SaldoPendiente}</span>
                              </> :
                              <>
                                <span>${Clientes.SaldoPendiente}</span>
                              </>
                          }
                        </h4>
                      </li> */}
                    </ul>
                    <div className="division bg-belmeny my-3"></div>
                    {
                      (loading) ?
                        <>
                          <div className="text-center">
                            <div className="m-auto spinner">
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            </div>
                          </div>
                        </> :
                        <>
                        <h5 className="text-center text-belmeny mt-4">Porcentaje de Top 100 Productos Ingco Anual</h5>
                          <Chart
                            options={chartData.options}
                            series={chartData.series}
                            type="area"
                            width="100%"
                            height="450px"
                          />
                        <h5 className="text-center text-belmeny mt-4">Porcentaje de Top 100 Productos Vert Anual</h5>
                          <Chart
                              options={chartData2.options}
                              series={chartData2.series}
                              type="area"
                              width="100%"
                              height="450px"
                            />
                        </>
                    }
                  </div>
              }

            </div>

          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showDetalleCliente} fullscreen={fullscreen} onHide={() => setShowDetalleCliente(false)}>
        {
          (loading) ?
            <>
              <div className="text-center">
                <div className="m-auto spinner">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </div>
            </> :
            <>
              <Modal.Header className='bg-belmeny text-light'>
                <Modal.Title className='fst-italic'>
                  Información del cliente: {clienteData.Nombre}
                </Modal.Title>
                <Modal.Title>
                  <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShowDetalleCliente(false)} />
                </Modal.Title>
              </Modal.Header>
              <div className="division w-100 my-4 bg-belmeny"></div>
              <Modal.Body>

                <div className="container-fluid">
                  {/* Div para escritorio */}
                  <div className='d-none d-md-block d-lg-block'>
                    <div className="row">
                      <div className="col">
                        <h2 className='text-center text-belmeny'>{clienteData.Nombre}</h2>
                        <div className="division w-25 m-auto my-3 bg-belmeny"></div>

                        <div className="text-center">
                          <h4 className='badge-vendedor rounded-pill text-light m-auto drop-shadow' style={{ width: "40%" }}>VENDEDOR ENCARGADO</h4>
                          <h3 className='bg-belmeny rounded-pill text-light fw-light m-auto drop-shadow' style={{ width: "60%" }}>{vendedorInfo}</h3>
                        </div>

                        <ul className='mt-5'>
                          <li className='client-info'>
                            <h4><b>Telefono:</b> {clienteData.Telefono1}</h4>
                          </li>
                          <li className='client-info'>
                            <h4><b>Dirección Fiscal:</b> {clienteData.DireccionFiscal}</h4>
                          </li>
                          <li className='client-info'>
                            <h4><b>Limite:</b> ${clienteData.Limite}</h4>
                          </li>
                          <li className='client-info'>
                            <h4>
                              <b>Saldo Pendiente por Cobrar: </b>
                              {
                                (clienteData.SaldoPendiente > 0) ? <><span className='text-danger'> ${clienteData.SaldoPendiente}</span></> : <><span className="text-success">0</span></>
                              }
                            </h4>
                          </li>
                        </ul>

                      </div>
                      <div className="col">
                        <h3 className='text-end text-belmeny me-3 fst-italic'>{clienteData.Rif}</h3>
                        {
                          (loading) ?
                            <>
                              <div className="text-center">
                                <div className="m-auto spinner">
                                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                </div>
                              </div>
                            </> :
                            <>
                              <Chart
                                options={chartData.options}
                                series={chartData.series}
                                type="area"
                                width="100%"
                                height="450px"
                              />
                            </>
                        }
                      </div>

                      <div className="division mt-5 bg-belmeny"></div>
                      <h3 className="text-end text-belmeny fst-italic mt-2 pe-5">Belmeny Group C.A</h3>
                    </div>
                  </div>

                  {/* Div para móvil */}
                  <div className='d-sm-block d-md-none'>
                    <div className="row">
                      <div className="row">
                        <h4 className='text-center text-belmeny fst-italic'>{clienteData.Rif}</h4>
                        <h3 className='text-center text-belmeny'>{clienteData.Nombre}</h3>
                        <div className="division w-50 m-auto my-3 bg-belmeny"></div>
                        <div className="text-center">
                          <h4 className='badge-vendedor rounded-pill text-light m-auto' style={{ width: "80%", position: "relative", left: "auto", top: "15%" }}>VENDEDOR ENCARGADO</h4>
                          <h3 className='bg-belmeny rounded-pill text-light fw-light m-auto pt-3' style={{ width: "100%" }}>{vendedorInfo}</h3>
                        </div>
                      </div>
                      <ul className='mt-5'>
                        <li className='client-info'>
                          <h4><b>Telefono:</b> {clienteData.Telefono1}</h4>
                        </li>
                        <li className='client-info'>
                          <h4><b>Dirección Fiscal:</b> {clienteData.DireccionFiscal}</h4>
                        </li>
                        <li className='client-info'>
                          <h4><b>Limite:</b> ${clienteData.Limite}</h4>
                        </li>
                        <li className='client-info'>
                          <h4>
                            <b>Saldo Pendiente por Cobrar:</b>
                            {
                              (clienteData.SaldoPendiente > 0) ?
                                <>
                                  <span className='text-danger'>${clienteData.SaldoPendiente}</span>
                                </> :
                                <>
                                  <span>${clienteData.SaldoPendiente}</span>
                                </>
                            }
                          </h4>
                        </li>
                      </ul>
                      <div className="division bg-belmeny my-3"></div>
                      {
                        (loading) ?
                          <>
                            <div className="text-center">
                              <div className="m-auto spinner">
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                              </div>
                            </div>
                          </> :
                          <>
                            <Chart
                              options={chartData.options}
                              series={chartData.series}
                              type="area"
                              width="100%"
                              height="450px"
                            />
                          </>
                      }
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </>
        }
      </Modal>
    </>
  )
}
