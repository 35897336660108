import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import AuthUser from "../../../components/AuthUser";
import { PresupuestoPDF } from "../../PDF/PresupuestoPDF";
import { PresupuestosXLS } from "../../XLS/PresupuestosXLS";
import { ProductosPresupuestos } from "./ProductosPresupuestos";
import { ProductosPresupuestosMobile } from "./ProductosPresupuestosMobile";

import { RequestPage } from "../../../api/request";
import {
  getProductosPresupuestos,
  getProductosPresupuestos2,
  convertPresupuesto,
  deletePresupuestoEncabezadoDetalle,
  getSubtotalPresupuesto
} from "../../../api/requestPresupuestos";
import { getClienteRIF } from "../../../api/requestClientes";

import Pagination from "react-responsive-pagination";
import { Dropdown, ButtonToolbar } from "rsuite";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  RiCloseCircleFill,
  RiFileCloudFill,
  RiFileEditFill
} from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";
import { AiFillEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const DetallePresupuesto = (props) => {
  var Presupuesto = props.item;
  // console.log(Presupuesto)

  // State del usuario
  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;
  const navigate = useNavigate();

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // State del loading
  const [loading, setLoading] = useState(true);

  // Sweet alert
  const MySwal = withReactContent(Swal);
  const customSwal = MySwal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger ms-2",
      denyButton: "btn btn-danger"
    },
    buttonsStyling: false
  });

  // State de datos
  const [productos, setProductos] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [cantProds, setCantProds] = useState(0);

  // States de paginas
  const [page, setPage] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [urlPage, setUrlPage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  // States de botones
  const [disableBtn, setDisableBtn] = useState(false);

  function handleShow() {
    setFullscreen(true);
    setShow(true);
    loadProductos();
  }

  const loadProductos = async () => {
    setLoading(true);

    const [ProductosPresupuestos, SubtotalPresupuesto, Lubricante] = await Promise.all([
      getProductosPresupuestos(Presupuesto.Documento),
      getSubtotalPresupuesto(Presupuesto.Documento),
      getProductosPresupuestos2(Presupuesto.Documento)
    ]);

    const res = await ProductosPresupuestos;
    const resSubtotal = await SubtotalPresupuesto;
    const reslubricante = await Lubricante;

    setCantProds(resSubtotal[0].CantProductos);
    setSubTotal(resSubtotal[0].Subtotal);
    setPage(res.current_page);
    setTotalPages(res.last_page);
    setUrlPage(res.first_page_url);

    setProductos(res.data);
    setLoading(false);
  };

  const loadPage = async (pageData) => {
    setLoading(true);

    const productoData = await RequestPage(pageData);

    setPage(productoData.current_page);
    setTotalPages(productoData.last_page);
    setUrlPage(productoData.first_page_url);

    setProductos(productoData.data);
    setLoading(false);
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    loadPage(
      urlPage.replace(/.$/, page + "&Documento=" + Presupuesto.Documento)
    );
  };

  const convertirPresupuesto = async (Documento) => {
    setDisableBtn(true);
    var clickValidation = 0;
    customSwal
      .fire({
        title: "¿Desea convertir este presupuesto a pedido?",
        showCancelButton: true,
        confirmButtonText: "Si, estoy seguro",
        cancelButtonText: "Cancelar",
        icon: "question"
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const queryProductos = await getProductosPresupuestos(
            Presupuesto.Documento
          );
          const queryProductos2 = await getProductosPresupuestos2(
            Presupuesto.Documento
          );

          if (queryProductos.data.length === 0) {
            customSwal
              .fire({
                title: "¡Ha ocurrido un error!",
                text: "Este presupuesto no se ha podido convertir ya que hubo un error al registrar los productos. Realice nuevamente el presupuesto por favor.",
                icon: "error",
                showConfirmButton: false,
                showCancelButton: false,
                timer: 10000,
                timerProgressBar: true
              })
              .then(() => {
                setDisableBtn(false);
              });
          } else {
            const inputOptions = {
              NotaEntrega: "Nota de Entrega"
            };
            const lubricanteValue = Number(queryProductos2[0]?.Lubricante);


            // Si Lubricante es diferente de 1, añadimos la opción Factura
            if (lubricanteValue !== 1) {
              inputOptions.Factura = "Factura";
            }

           // console.log("inputOptions:", inputOptions);



            const { value: formaFiscal } = await customSwal.fire({
              title: "Indique la forma fiscal del pedido",
              icon: "info",
              input: "select",
              inputOptions: inputOptions,
              inputPlaceholder: "Seleccione una forma fiscal",
              showCancelButton: true,
              inputValidator: (value) => {
                return new Promise((resolve) => {
                  if (value) {
                    resolve();
                  } else {
                    resolve("¡Debes seleccionar una forma fiscal!");
                  }
                });
              }
            });

            const { value: comentario } = await customSwal.fire({
              title: "Agrega un comentario para el pedido",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Enviar",
              cancelButtonText: "Cancelar",
              allowOutsideClick: false,
              input: "text",
              inputValidator: (value) => {
                return new Promise((resolve) => {
                  if (value) {
                    clickValidation = 1;
                    resolve();
                  } else {
                    resolve("¡El comentario no debe quedar vacío!");
                  }
                });
              }
            });

            // 2. Modificar el comentario si queryProductos.data.Lubricante es '0'
            let comentarioFinal = comentario;
            if (lubricanteValue === 1) {
              comentarioFinal = `LUBRICANTE ${comentario}`;
            }

            const convertirPromesa = new Promise((resolve, reject) => {
              if (
                formaFiscal &&
                comentario !== undefined &&
                clickValidation === 1
              ) {
                resolve();
              } else {
                reject();
              }
            });

            convertirPromesa
              .then(async (fulfilled) => {
                var Responsable = user.CodVendedor + " - " + user.Nombre;
                const convertirAPedido = await convertPresupuesto(
                  Documento,
                  formaFiscal,
                  comentarioFinal,
                  Responsable
                );
                if (convertirAPedido.status === 200) {
                  customSwal
                    .fire({
                      title:
                        "¡Presupuesto convertido a pedido de manera exitosa!",
                      text:
                        "Número de pedido: " +
                        convertirAPedido.datosEncabezado.Documento,
                      icon: "success",
                      showConfirmButton: false,
                      showLoaderOnConfirm: true,
                      timer: 3000,
                      timerProgressBar: true
                    })
                    .then(() => {
                      window.location.reload();
                      setDisableBtn(false);
                    });
                } else if (convertirAPedido.status === 423) {
                  // HTTP STATUS 423 SIGNIFICA BLOQUEADO
                  customSwal
                    .fire({
                      title: "Opción deshabilitada",
                      text: "La función de convertir presupuestos ha sido puesta bajo mantenimiento. Se informará cuando este disponible nuevamente.",
                      icon: "info",
                      showConfirmButton: false,
                      showLoaderOnConfirm: true,
                      timer: 10000,
                      timerProgressBar: true
                    })
                    .then(() => {
                      setDisableBtn(false);
                    });
                } else if (convertirAPedido.status === 409) {
                  // HTTP STATUS 409 SIGNIFICA CONFLICTO
                  customSwal
                    .fire({
                      title:
                        "Hubo un problema al procesar el Presupuesto, No cargaron todos los productos",
                      text: "Verifique su conexion e Intentelo otra vez",
                      icon: "info",
                      showConfirmButton: false,
                      showLoaderOnConfirm: true,
                      timer: 10000,
                      timerProgressBar: true
                    })
                    .then(() => {
                      setDisableBtn(false);
                    });
                } else if (convertirAPedido.status === 424) {
                  // HTTP STATUS 409 SIGNIFICA CONFLICTO
                  if (ZonasVenta === "001,003,004,CCS") {
                    customSwal
                      .fire({
                        title:
                          "Los productos no cuentan con la existencia suficiente para el pedido, por favor elimine o modifique los siguientes productos.",
                        text: "Codigos: " + convertirAPedido.message + " ",
                        icon: "info",
                        showConfirmButton: false,
                        showLoaderOnConfirm: true,
                        timer: 40000,
                        timerProgressBar: true
                      })
                      .then(() => {
                        setDisableBtn(false);
                      });
                  } else {
                    customSwal
                      .fire({
                        title:
                          "Los productos no cuentan con la existencia suficiente para el pedido, por favor elimine o modifique los siguientes productos.",
                        text: "Codigos: " + convertirAPedido.message2,
                        icon: "info",
                        showConfirmButton: false,
                        showLoaderOnConfirm: true,
                        timer: 20000,
                        timerProgressBar: true
                      })
                      .then(() => {
                        setDisableBtn(false);
                      });
                  }
                } else {
                  customSwal
                    .fire({
                      title: "¡Ha ocurrido un error!",
                      text: "Al parecer este presupuesto ya ha sido convertido, por favor verifique consultando los pedidos o comuniquese con el Dpto. de Sistemas.",
                      icon: "info",
                      showConfirmButton: false,
                      showLoaderOnConfirm: true,
                      timer: 5000,
                      timerProgressBar: true
                    })
                    .then(() => {
                      window.location.reload();
                      setDisableBtn(false);
                    });
                }
              })
              .catch((rejected) => {
                console.error(rejected);
                setDisableBtn(false);
              });
          }
          // const resClient = await getClienteRIF(Presupuesto.Codcliente)
          // console.log(resClient)
          // if (resClient[0].SaldoPendiente > 0 && (resClient[0].Vendedor !== 'V1' && resClient[0].Vendedor !== 'T1' && resClient[0].Vendedor !== 'V2')) {
          //   customSwal.fire({
          //     title: '¡Ups! No se ha podido convertir el presupuesto a pedido',
          //     text: `El cliente ${resClient[0].Nombre} tiene una deuda pendiente de $${resClient[0].SaldoPendiente}. Por favor comuniquese con el Departamento de Cobranzas.`,
          //     icon: 'warning',
          //     showConfirmButton: false,
          //     showLoaderOnConfirm: true,
          //     timer: 5000,
          //     timerProgressBar: true
          //   })
          //   setDisableBtn(false)
          // } else {

          // }
        } else if (result.isDismissed) {
          // Code for dismiss goes here...
          setDisableBtn(false);
        }
      });
  };

  const deletePresupuesto = async (Documento, CodCliente) => {
    customSwal
      .fire({
        title: "¿Desea eliminar este presupuesto?",
        text: "¡Esta acción es irreversible!",
        showCancelButton: true,
        confirmButtonText: "Si, estoy seguro",
        cancelButtonText: "Cancelar",
        icon: "question"
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const res = await deletePresupuestoEncabezadoDetalle(
            Documento,
            CodCliente
          );

          customSwal
            .fire({
              title: "¡Presupuesto eliminado de manera exitosa!",
              icon: "success",
              showConfirmButton: false,
              showLoaderOnConfirm: true,
              timer: 3000,
              timerProgressBar: true
            })
            .then(() => {
              window.location.reload();
            });
        } else if (result.isDismissed) {
          // Code for dismiss goes here...
        }
      });
  };

  return (
    <>
      <tr className="d-none-table">
        <td className="text-danger fw-bold text-center">
          {Presupuesto.Documento}
        </td>
        <td>{Presupuesto.NombreCliente}</td>
        <td className="d-md-block-table d-none-table fw-bold text-success text-center">
          ${Presupuesto.Monto}
        </td>
        <td className="text-center">
          {new Date(Presupuesto.FechaPresupuesto).toLocaleDateString("es-MX")}
        </td>
        <td>
          <p
            className="btn btn-primary btn-sm mt-2 mb-2 w-100"
            onClick={() => handleShow()}
          >
            Ver detalles
          </p>

          {Presupuesto.Convertido === 0 ? (
            <>
              {disableBtn ? (
                <button className="btn btn-success btn-sm mb-2 w-100" disabled>
                  Convertir a pedido
                </button>
              ) : (
                <button
                  className="btn btn-success btn-sm mb-2 w-100"
                  onClick={(e) => convertirPresupuesto(Presupuesto.Documento)}
                >
                  Convertir a pedido
                </button>
              )}
              <Link
                className="btn btn-primary btn-sm mb-2 w-100"
                to={Presupuesto.Lubricante === 1 ? `/preorders/modify2/${Presupuesto.Documento}` : `/preorders/modify/${Presupuesto.Documento}`}
              >
                Modificar Presupuesto
              </Link>
              <button
                className="btn btn-danger btn-sm mb-2 w-100"
                onClick={(e) =>
                  deletePresupuesto(
                    Presupuesto.Documento,
                    Presupuesto.Codcliente
                  )
                }
              >
                Eliminar Presupuesto
              </button>
            </>
          ) : (
            <>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    ¡Este presupuesto ya fue convertido a pedido!
                  </Tooltip>
                }
                className="w-100"
              >
                <span className="d-flex">
                  <Button
                    disabled
                    style={{ pointerEvents: "none" }}
                    className="btn btn-success btn-sm mb-2 w-100"
                  >
                    Convertir a pedido
                  </Button>
                </span>
              </OverlayTrigger>
            </>
          )}
          <PresupuestoPDF user={user} Presupuesto={Presupuesto} />
          <PresupuestosXLS
            user={user}
            Presupuesto={Presupuesto}
            fileName={"Presupuesto " + Presupuesto.Documento}
          />
        </td>
      </tr>

      <div className="container-fluid d-sm-block d-md-none">
        <div className="card my-5 drop-shadow border-belmeny">
          <div className="card-body">
            <div className="row" style={{ zIndex: "-1" }}>
              <div className="col-9">
                <h5 className="card-title text-belmeny">
                  {Presupuesto.NombreCliente.toUpperCase()}
                </h5>
              </div>
              <div className="col-3" style={{ zIndex: "100" }}>
                <ButtonToolbar className="">
                  <Dropdown
                    title={<SlOptionsVertical />}
                    className="border-belmeny"
                    style={{ zIndex: "100" }}
                  >
                    <Dropdown.Item eventKey="details">
                      <AiFillEye
                        className="text-belmeny fs-2"
                        onClick={() => handleShow()}
                      />
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="report">
                      <PresupuestoPDF user={user} Presupuesto={Presupuesto} />
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="modificar">
                      <Link
                        className="mb-2"
                        to={`/preorders/modify/${Presupuesto.Documento}`}
                      >
                        <RiFileEditFill className="text-belmeny fs-2" />
                      </Link>
                    </Dropdown.Item>
                    {Presupuesto.Convertido === 0 ? (
                      <Dropdown.Item eventKey="convert">
                        <RiFileCloudFill
                          className="text-belmeny fs-2"
                          onClick={(e) =>
                            convertirPresupuesto(Presupuesto.Documento)
                          }
                        />
                      </Dropdown.Item>
                    ) : (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ¡Este presupuesto ya fue convertido a pedido!
                          </Tooltip>
                        }
                        className="w-100"
                      >
                        <span className="d-flex">
                          <Dropdown.Item
                            eventKey="convert"
                            disabled
                            style={{ pointerEvents: "none" }}
                          >
                            <RiFileCloudFill className="text-muted fs-2" />
                          </Dropdown.Item>
                        </span>
                      </OverlayTrigger>
                    )}
                    <Dropdown.Item eventKey="borrar">
                      <FaTrash
                        className="text-belmeny fs-2"
                        onClick={() =>
                          deletePresupuesto(
                            Presupuesto.Documento,
                            Presupuesto.Codcliente
                          )
                        }
                      />
                    </Dropdown.Item>
                  </Dropdown>
                </ButtonToolbar>
              </div>
            </div>
            <h6 className="card-subtitle mb-2 text-muted fst-italic">
              {Presupuesto.Documento}
            </h6>
            <h6 className="card-subtitle mb-2 text-muted fst-italic">
              Convertido a Pedido:{" "}
              {Presupuesto.Convertido === 1 ? (
                <span className="text-danger text-uppercase">SI</span>
              ) : (
                <span className="text-danger text-uppercase">NO</span>
              )}
            </h6>
            <h6>
              Monto:{" "}
              <span className="text-success fw-bold">${Presupuesto.Monto}</span>
            </h6>
            <h6 className="my-1">
              Fecha:{" "}
              {new Date(Presupuesto.FechaPresupuesto).toLocaleDateString(
                "es-MX"
              )}
            </h6>
          </div>
        </div>
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className="bg-belmeny text-light">
          <Modal.Title className="fst-italic">
            Información del presupuesto: {Presupuesto.Documento}
          </Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill
              className="text-danger fs-1 modal-close bg-light rounded-circle"
              onClick={() => setShow(false)}
            />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-4"></div>
        <Modal.Body>
          <div className="container-fluid">
            {/* Body for desktop version */}
            <div className="d-none d-md-block">
              <h3 className="bg-belmeny text-light rounded-pill text-center w-50 m-auto py-1 mb-3">
                Vendedor Encargado: {user.Nombre}
              </h3>
              <div className="row badge-vendedor rounded-pill">
                <div className="col">
                  <h3 className="text-start ms-3">
                    RIF: {Presupuesto.Codcliente}
                  </h3>
                </div>
                <div className="col">
                  <h4 className="text-end me-4">{Presupuesto.NombreCliente}</h4>
                </div>
              </div>

              <div className="container mt-3">
                <div className="row text-center">
                  <div className="col">
                    <h4>
                      <b>Fecha del presupuesto:</b>{" "}
                      {new Date(
                        Presupuesto.FechaPresupuesto
                      ).toLocaleDateString("es-MX")}
                    </h4>
                    <h4>
                      <b>Total de productos:</b> {cantProds}
                    </h4>
                  </div>
                  <div className="col">
                    <h4>
                      <b>Forma de pago:</b> {Presupuesto.FormaPago}
                    </h4>
                    <h4>
                      <b>¿Fue convertido a pedido?:</b>{" "}
                      {Presupuesto.Convertido === 1 ? "Si" : "No"}
                    </h4>
                    {Presupuesto.FormaPago === "PREPAGO" ? (
                      <>
                        <h4>
                          <b>¿Aplica descuento?</b> Si
                        </h4>
                        <h4>
                          <b>
                            Monto del descuento:{" "}
                            <span className="text-success">
                              ${(subTotal * 0.1).toFixed(2)}
                            </span>
                          </b>
                        </h4>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <h3 className="bg-belmeny m-auto w-50 rounded-pill py-2 text-center text-light mt-3">
                Lista de productos
              </h3>
              {loading ? (
                <>
                  <div
                    style={{
                      position: "fixed",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)"
                    }}
                  >
                    <span className="loader-documentos"></span>
                  </div>
                </>
              ) : (
                <>
                  <div className="my-3">
                    <table className="table table-responsive table-bordered table-hover border-belmeny">
                      <thead className="bg-belmeny text-light text-center">
                        <tr>
                          <th style={{ width: "10%" }}>Imagén</th>
                          <th>Código</th>
                          <th>Producto</th>
                          <th>Precio Unitario</th>
                          <th>Cantidad</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productos.map((item) => (
                          <ProductosPresupuestos
                            item={item}
                            key={item.Codigo}
                            ZonasVenta={ZonasVenta}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    onPageChange={(page) => handlePageChange(page)}
                  />

                  <div className="row mt-3">
                    {Presupuesto.FormaPago === "PREPAGO" ? (
                      <>
                        <div className="col">
                          <h4>Subtotal: </h4>
                          <h2 className="text-end pe-5 fs-1 text-success">
                            ${subTotal.toFixed(2)}
                          </h2>
                        </div>
                        <div className="col">
                          <h4>Descuento: </h4>
                          <h2 className="text-end pe-5 fs-1 text-success">
                            ${(subTotal * 0.1).toFixed(2)}
                          </h2>
                        </div>
                        <div className="col">
                          <h4>Total Final: </h4>
                          <h2 className="text-end pe-5 fs-1 text-success">
                            ${Presupuesto.Monto}
                          </h2>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col">
                          <h4>Subtotal: </h4>
                          <h2 className="text-end pe-5 fs-1 text-success">
                            ${Presupuesto.Monto}
                          </h2>
                        </div>
                        <div className="col">
                          <h4>Descuento: </h4>
                          <h2 className="text-end pe-5 fs-1">$0</h2>
                        </div>
                        <div className="col">
                          <h4>Total Final: </h4>
                          <h2 className="text-end pe-5 fs-1 text-success">
                            ${Presupuesto.Monto}
                          </h2>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>

            {/* Body for mobile version */}
            <div className="d-block d-sm-block d-md-none">
              <h5 className="bg-belmeny text-light rounded-pill text-center m-auto py-1 mb-3">
                Vendedor Encargado: {user.Nombre}
              </h5>
              <div className="badge-vendedor rounded-pill py-2">
                <div className="row">
                  <h5 className="text-center ms-3">
                    RIF: {Presupuesto.Codcliente}
                  </h5>
                </div>
                <div className="row">
                  <h5 className="text-center me-4">
                    {Presupuesto.NombreCliente}
                  </h5>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col text-center">
                  <h6>
                    <b>Fecha del pedido:</b>{" "}
                    {new Date(Presupuesto.FechaPresupuesto).toLocaleDateString(
                      "es-MX"
                    )}
                  </h6>
                  <h6>
                    <b>Forma de pago:</b> {Presupuesto.FormaPago}
                  </h6>
                  <h6>
                    <b>¿Fue convertido a pedido?:</b>{" "}
                    {Presupuesto.Convertido === 1 ? "Si" : "No"}
                  </h6>
                  <h6>
                    <b>Total de productos:</b> {cantProds}
                  </h6>
                  {Presupuesto.FormaPago === "PREPAGO" ? (
                    <>
                      <h6>
                        <b>¿Aplica descuento?</b> Si
                      </h6>
                      <h6>
                        <b>
                          Monto del descuento: ${(subTotal * 0.1).toFixed(2)}
                        </b>
                      </h6>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <h5 className="bg-belmeny m-auto w-100 rounded-pill py-2 text-center text-light mt-3">
                Lista de productos
              </h5>
              {loading ? (
                <>
                  <div
                    style={{
                      position: "fixed",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)"
                    }}
                  >
                    <span className="loader-documentos"></span>
                  </div>
                </>
              ) : (
                <>
                  <div className="my-3">
                    {productos.map((item) => (
                      <ProductosPresupuestosMobile
                        item={item}
                        key={item.Codigo}
                        ZonasVenta={ZonasVenta}
                      />
                    ))}
                  </div>

                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    onPageChange={(page) => handlePageChange(page)}
                  />

                  <div className="row mt-4">
                    {Presupuesto.FormaPago === "PREPAGO" ? (
                      <>
                        <div className="row">
                          <div className="col">
                            <h4>
                              Subtotal:{" "}
                              <span className="text-success">
                                ${subTotal.toFixed(2)}
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <h4>
                              Descuento:{" "}
                              <span className="text-success">
                                ${(subTotal * 0.1).toFixed(2)}
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <h4>
                              Total Final:{" "}
                              <span className="text-success">
                                ${Presupuesto.Monto}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col">
                            <h4>
                              Subtotal:{" "}
                              <span className="text-success">
                                ${Presupuesto.Monto}
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <h4>
                              Descuento:{" "}
                              <span className="text-success">$0.00</span>
                            </h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <h4>
                              Total Final:{" "}
                              <span className="text-success">
                                ${Presupuesto.Monto}
                              </span>
                            </h4>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
