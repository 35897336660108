import axios from "axios";
import { baseURL } from "./ApiURL"
//import AuthUser from '../components/AuthUser';

//const { user } = AuthUser();
//const ZonasVenta = user.ZonasVenta;

/*
==================================================================================
PRODUCTOS
==================================================================================
*/


// Productos vendidos anteriormente
export async function getSugeridos(RifCliente, marca, ZonasVenta, vistaFlag ) {
  try {
    const res = await axios.get(baseURL + `/getSugeridos?RifCliente=${RifCliente}&marca=${marca}&ZonasVenta=${ZonasVenta}&vistaFlag=${vistaFlag}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getSugeridosByFecha(RifCliente, fechaInicio, fechaFin, ZonasVenta, vistaFlag) {
  try {
    const res = await axios.get(baseURL + `/getSugeridosByFecha?RifCliente=${RifCliente}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&ZonasVenta=${ZonasVenta}&vistaFlag=${vistaFlag}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getProductoByCodigo(Codigo, ZonasVenta, rif, lubricante,vistaFlag) {
  try {
    const res = await axios.get(baseURL + `/getProductoByCodigo?Codigo=${Codigo}&ZonasVenta=${ZonasVenta}&rif=${rif}&lubricante=${lubricante}&vistaFlag=${vistaFlag}`)
    return res;
  } catch (error) {
    console.error(error)
  }
}

export async function getProductoByNombre(Nombre, ZonasVenta,rif, lubricante,vistaFlag ) {
  try {
    const res = await axios.get(baseURL + `/getProductoByNombre?Nombre=${Nombre}&ZonasVenta=${ZonasVenta}&rif=${rif}&lubricante=${lubricante}&vistaFlag=${vistaFlag}`)
    return res;
  } catch (error) {
    console.error(error)
  }
}


export async function getArticulosAndFotos() {
  try {
    const res = await axios.get(baseURL + `/getArticulosAndFotos`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTopProductos(marca, ZonasVenta, rif ,fechaInicio, fechaFin, vistaFlag) {
  try {
    var res;
    if (marca === 'INGCO') {
      res = await axios.get(baseURL + `/getTopProductos?marca=INGCO&ZonasVenta=${ZonasVenta}&rif=${rif}&vistaFlag=${vistaFlag}`)
    } else if (marca === 'VERT') {
      res = await axios.get(baseURL + `/getTopProductos?marca=VERT&ZonasVenta=${ZonasVenta}&rif=${rif}&vistaFlag=${vistaFlag}`)
    } else if (marca !== 'INGCO' && marca !== 'VERT') {
      res = await axios.get(baseURL + `/getTopProductos?marca=${marca}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&ZonasVenta=${ZonasVenta}&rif=${rif}&vistaFlag=${vistaFlag}`)
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTopProductos2(marca, ZonasVenta, rif,vistaFlag) {
  try {
    var res;
    if (marca === 'INGCO') {
      res = await axios.get(baseURL + `/getTopProductos2?marca=${marca}&ZonasVenta=${ZonasVenta}&rif=${rif}&vistaFlag=${vistaFlag}`)
    } else{
      res = await axios.get(baseURL + `/getTopProductos2?marca=${marca}&ZonasVenta=${ZonasVenta}&rif=${rif}&vistaFlag=${vistaFlag}`)
    } 
    return res.data;
  } catch (error) {
    console.error(error)
  }
}
export async function getTopProductos2XLS(marca, ZonasVenta, rif) {
  try {
    var res;
    if (marca === 'INGCO') {
      res = await axios.get(baseURL + `/getTopProductos2XLS?marca=${marca}&ZonasVenta=${ZonasVenta}&rif=${rif}`)
    } else{
      res = await axios.get(baseURL + `/getTopProductos2XLS?marca=${marca}&ZonasVenta=${ZonasVenta}&rif=${rif}`)
    } 
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getProductosPromocion(ZonasVenta) {
  try {
    var res = await axios.get(baseURL + `/getProductosEnPromocion&ZonasVenta=${ZonasVenta}`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getGeneralProductos(catalogo, ZonasVenta, rif,vistaFlag) {
  try {
    var res = await axios.get(baseURL + `/getGeneralProductos?catalogo=${catalogo}&ZonasVenta=${ZonasVenta}&rif=${rif}&vistaFlag=${vistaFlag}`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getListaPrecioCadenas(Vendedor, ZonasVenta, Marca) {
  try {
    var res = await axios.get(baseURL + `/getListaPrecioCadenas?Vendedor=${Vendedor}&ZonasVenta=${ZonasVenta}&Marca=${Marca}`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}