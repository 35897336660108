import React from 'react'

import { IoMailUnread, IoPaperPlane } from "react-icons/io5";

import belmenyLogo from '../assets/img/logo-png.webp';

export const Footer = () => {
  const phoneNumber = '04246589484';
  const email = 'vert@belmeny.com';

  const handlePhoneClick = () => {
    window.open(`https://wa.me/58${phoneNumber}`, '_blank');
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`;
  };
  return (
    <>
      <div className="footer bg-belmeny-gradient mt-5">
        <div className="container text-light pt-3 pb-3">
          <div className="row text-center">
            <div className="col">
              Direccion:
              <p>Belmeny Group Caracas <br />
              Belmeny Group Maracaibo</p>
            </div>
            <div className="col">
              <img width={80} src={belmenyLogo} alt="Belmeny Logo" className='text-center drop-shadow-sm' />
              <br />
            </div>
            <div className="col">
            Contacto:
              <p>
                <IoPaperPlane
                  style={{ cursor: 'pointer' }}
                  onClick={handlePhoneClick}
                />{' '}
                <span style={{ cursor: 'pointer' }} onClick={handlePhoneClick}>
                  {phoneNumber}
                </span>
                <br />
                <IoMailUnread
                  style={{ cursor: 'pointer' }}
                  onClick={handleEmailClick}
                />{' '}
                <span style={{ cursor: 'pointer' }} onClick={handleEmailClick}>
                  {email}
                </span>
              </p>
            </div>
          </div>
          <hr />
          {/* 2022 <small>Belmeny Group © {new Date().getFullYear()}</small> */}
          <small>Belmeny Group © 2025</small>
        </div>
      </div>
    </>
  )
}
