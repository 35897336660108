import React, { useState, useEffect } from 'react'

import AuthUser from '../../../components/AuthUser';

import { Dropdown, ButtonToolbar } from 'rsuite';
import { Modal } from 'react-bootstrap';
import { RiCloseCircleFill, RiPrinterFill } from "react-icons/ri";
import { SlOptionsVertical, SlOptions } from "react-icons/sl";
import { AiFillEye } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";

import {
  pedidoCreadoPendiente, pedidoCreadoProceso, pedidoCreadoFinalizado,
  almacenProcesadoPendiente, almacenProcesadoProceso, almacenProcesadoFinalizado,
  verificadoEmbaladoPendiente, verificadoEmbaladoProceso, verificadoEmbaladoFinalizado,
  pedidoFacturadoPendiente, pedidoFacturadoProceso, pedidoFacturadoFinalizado,
  guiaEnviadaPendiente, guiaEnviadaProceso, guiaEnviadaFinalizado,
  salidaRepartoPendiente, salidaRepartoProceso, salidaRepartoFinalizado,
} from '../../../assets/img'

export const DetalleTrackingSupervisor = (props) => {

  var Tracking = props.item;
  var TrackingBuscado = props.found;
  var TrackingBuscadoFecha = props.foundFecha
  var TrackingBuscadoCliente = props.foundCliente

  // State del usuario
  const { user } = AuthUser();

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos


  function handleShow() {
    setFullscreen(true);
    setShow(true);
  }

  useEffect(() => {
  }, []);

  return (
    <>
      <tr className='d-none-table'>
        <td className='text-danger fw-bold'>
          {Tracking.Documento}
        </td>
        <td>
          {Tracking.NombreCliente}
        </td>
        <td>
          {Tracking.Sector}
        </td>
        <td>
          {Tracking.NombreVendedor}
        </td>
        <td >
          {new Date(Tracking.FechaCreacion).toLocaleDateString('es-MX')}
        </td>
        <td>
          {(Tracking.FechaAnulacion === "0000-00-00 00:00:00") ? <>🔵 No Anulado</> : <>🔴🔴 ANULADO</>}
        </td>
        <td className='d-md-block-table d-none-table '>
          <p className="btn btn-success btn-sm mt-2 mb-2 w-100" onClick={() => handleShow()}>Ver detalles</p>
        </td>
      </tr>

      <div className="container-fluid d-sm-block d-md-none">
        <div className="card my-3 drop-shadow border-belmeny">
          <div className="card-body">
            <div className="row">
              <div className="col-9">
                <h5 className="card-title text-belmeny">{Tracking.NombreCliente.toUpperCase()}</h5>
              </div>
              <div className="col-3">
                <SlOptionsVertical className='text-belmeny fs-5 float-end' style={{ cursor: 'pointer' }} onClick={() => handleShow()} />
              </div>
            </div>
            <h6 className="card-subtitle mb-2 text-muted fst-italic">{Tracking.Documento}</h6>
            <h6>ANULADO: {(Tracking.FechaAnulacion === "0000-00-00 00:00:00") ? <>🔵 No Anulado</> : <>🔴🔴 ANULADO</>}</h6>
            <h6 className='my-1'>Fecha: {new Date(Tracking.FechaCreacion.split(" ")[0]).toLocaleDateString('es-MX')}</h6>
          </div>
        </div>
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          {
            (TrackingBuscado) ?
              <>
                <Modal.Title className='fst-italic'>Información del tracking: {Tracking.Documento}</Modal.Title>
                <Modal.Title>
                  <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
                </Modal.Title>
              </> :
              (TrackingBuscadoFecha) ?
                <>
                  <Modal.Title className='fst-italic'>Información del tracking: {Tracking.Documento}</Modal.Title>
                  <Modal.Title>
                    <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
                  </Modal.Title>
                </> :
                <>
                  <Modal.Title className='fst-italic'>Información del tracking: {Tracking.Documento}</Modal.Title>
                  <Modal.Title>
                    <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
                  </Modal.Title>
                </>
          }
        </Modal.Header>
        <div className="division w-100 my-4"></div>
        <Modal.Body>
          <div className="container-fluid">
            {/* Body for desktop version */}
            <div className='d-none d-md-block'>
              <h3 className="bg-belmeny text-light rounded-pill text-center w-50 m-auto py-1 mb-3">Vendedor Encargado: {Tracking.CodVendedor} - {Tracking.NombreVendedor}</h3>
              <div className="row badge-vendedor rounded-pill m-auto">
                <div className="col">
                  <h3 className='text-center py-2'>{Tracking.NombreCliente}</h3>
                </div>
              </div>

              <div className="container-fluid px-1 mx-auto">
                <div className="card">
                  <div className="row d-flex justify-content-between px-3 top">
                    <div className="">
                      <h3>
                        Pedido:
                        <span className="text-primary font-weight-bold"> {Tracking.Documento}</span>
                      </h3>
                    </div>
                    <div className="d-flex flex-column text-sm-right">
                      <h4>
                        Seguimiento del pedido:
                      </h4>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-center my-3">
                    <div className="col">
                      <div className="trackingbar">
                        <div className="row m-auto text-center">
                          <div className="connectbar"></div>

                             {/* Ingreso al sistema */}
                             <div className="col my-auto">
                                  <h6 className="text-center mb-2">Ingresado al Sistema</h6>
                                  {
                                    (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                      <>
                                        <div className="progress-circle text-center align-middle m-auto d-flex">
                                          <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                            <img src={pedidoCreadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                                          </div>
                                        </div>
                                        <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                                      </> :
                                      // Finalizado
                                      (Tracking.FechaCreacion !== '0000-00-00 00:00:00' && Tracking.FinDepositario !== '0000-00-00 00:00:00') ?
                                        <>
                                          <div className="progress-circle text-center align-middle m-auto d-flex">
                                            <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                              <img src={pedidoCreadoFinalizado} alt="" className="p-1" style={{ width: "65px" }} />
                                            </div>
                                          </div>
                                          <h6 className="text-center mt-2">🟢 Finalizado</h6>
                                        </> :
                                        // En proceso
                                        (Tracking.FechaCreacion !== '0000-00-00 00:00:00' && Tracking.FinDepositario === '0000-00-00 00:00:00') ?
                                          <>
                                            <div className="progress-circle text-center align-middle m-auto d-flex">
                                              <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                                <img src={pedidoCreadoProceso} alt="" className="p-1" style={{ width: "65px" }} />
                                              </div>
                                            </div>
                                            <h6 className="text-center mt-2">🔵 En Proceso</h6>
                                          </> :
                                          // Pendiente
                                          <>
                                            <div className="progress-circle text-center align-middle m-auto d-flex">
                                              <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                                <img src={pedidoCreadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                                              </div>
                                            </div>
                                            <h6 className="text-center mt-2">🔴 Pendiente</h6>
                                          </>
                                  }
                                </div>

                               {/* Asignado en almacen */}
                               <div className="col my-auto">
                                  <h6 className="text-center mb-2">Asignado en Almacen</h6>
                                  {
                                    (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                      <>
                                        <div className="progress-circle text-center align-middle m-auto d-flex">
                                          <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                            <img src={almacenProcesadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                                          </div>
                                        </div>
                                        <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                                      </> :
                                      // Finalizado
                                      (Tracking.FinDepositario !== '0000-00-00 00:00:00') ?
                                        <>
                                          <div className="progress-circle text-center align-middle m-auto d-flex">
                                            <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                              <img src={almacenProcesadoFinalizado} alt="" className="p-1" style={{ width: "65px" }} />
                                            </div>
                                          </div>
                                          <h6 className="text-center mt-2">🟢 Finalizado</h6>
                                        </> :
                                        // En proceso                                        
                                          <>
                                            <div className="progress-circle text-center align-middle m-auto d-flex">
                                              <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                                <img src={almacenProcesadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                                              </div>
                                            </div>
                                            <h6 className="text-center mt-2">🔴 Pendiente</h6>
                                          </>
                                  }
                                </div>

                                {/* Verificado y Embalado */}
                                <div className="col my-auto">
                                  <h6 className="text-center mb-2">Verificado y Embalado</h6>
                                  {
                                    (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                      <>
                                        <div className="progress-circle text-center align-middle m-auto d-flex">
                                          <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                            <img src={verificadoEmbaladoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                                          </div>
                                        </div>
                                        <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                                      </> :
                                      // Finalizado
                                      (Tracking.FinEmpacador !== '0000-00-00 00:00:00') ?
                                        <>
                                          <div className="progress-circle text-center align-middle m-auto d-flex">
                                            <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                              <img src={verificadoEmbaladoFinalizado} alt="" className="pe-1" style={{ width: "65px" }} />
                                            </div>
                                          </div>
                                          <h6 className="text-center mt-2">🟢 Finalizado</h6>
                                        </> :
                                        // En proceso                                        
                                          // Pendiente
                                          <>
                                            <div className="progress-circle text-center align-middle m-auto d-flex">
                                              <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                                <img src={verificadoEmbaladoPendiente} alt="" className="pe-1" style={{ width: "65px" }} />
                                              </div>
                                            </div>
                                            <h6 className="text-center mt-2">🔴 Pendiente</h6>
                                          </>
                                  }
                                </div>

                                {/* Pedido Facturado */}
                                <div className="col my-auto">
                                  <h6 className="text-center mb-2">Facturado</h6>
                                  {
                                    (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                      <>
                                        <div className="progress-circle text-center align-middle m-auto d-flex">
                                          <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                            <img src={pedidoFacturadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                                          </div>
                                        </div>
                                        <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                                      </> :
                                      // Finalizado
                                      (Tracking.Facturacion !== '0000-00-00 00:00:00') ?
                                        <>
                                          <div className="progress-circle text-center align-middle m-auto d-flex">
                                            <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                              <img src={pedidoFacturadoFinalizado} alt="" className="pe-1" style={{ width: "65px" }} />
                                            </div>
                                          </div>
                                          <h6 className="text-center mt-2">🟢 Finalizado</h6>
                                        </>
                                        :                                        
                                          // En proceso                                          
                                            // Pendiente
                                            <>
                                              <div className="progress-circle text-center align-middle m-auto d-flex">
                                                <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                                  <img src={pedidoFacturadoPendiente} alt="" className="pe-1" style={{ width: "65px" }} />
                                                </div>
                                              </div>
                                              <h6 className="text-center mt-2">🔴 Pendiente</h6>
                                            </>
                                  }
                                </div>
                                

                                {/* Salida a reparto */}
                                <div className="col my-auto">
                                  <h6 className="text-center mb-2">Subiendo carga al camión y Salida</h6>

                                  {
                                    (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                      <>
                                        <div className="progress-circle text-center align-middle m-auto d-flex">
                                          <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                            <img src={salidaRepartoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                                          </div>
                                        </div>
                                        <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                                      </> :
                                      (Tracking.DocManifiesto !== "" && Tracking.FechaSalida !== '0000-00-00 00:00:00') ?
                                      <>
                                        <div className="progress-circle text-center align-middle m-auto d-flex">
                                          <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                            <img src={salidaRepartoFinalizado} alt="" className="p-1" style={{ width: "65px" }} />
                                          </div>
                                        </div>
                                        <h6 className="text-center mt-2">🟢 Finalizado</h6>
                                      </> :                                      
                                      <>
                                      <div className="progress-circle text-center align-middle m-auto d-flex">
                                        <div className="my-auto mx-auto bg-light rounded-circle px-1">
                                          <img src={salidaRepartoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                                        </div>
                                      </div>
                                      <h6 className="text-center mt-2">🔴 Pendiente</h6>
                                    </>
                                      // Pendiente
                                  }

                                </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <h6>Registro del tracking:</h6>

                    <table className="fw-bold">
                            <tbody>
                              <tr>
                                <td>Ingresado al sistema:</td>
                                <td>
                                  {
                                    (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                      <>
                                        <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                      </> :
                                      (Tracking.FechaCreacion !== '0000-00-00 00:00:00' && Tracking.FinDepositario !== '0000-00-00 00:00:00') ?
                                        <>
                                          <p>
                                            {new Date(Tracking.FechaCreacion).toLocaleDateString('es-MX')} a las {Tracking.FechaCreacion.split(" ")[1]} 🟢
                                          </p>
                                        </> :
                                        (Tracking.FechaCreacion !== '0000-00-00 00:00:00' && Tracking.FinDepositario === '0000-00-00 00:00:00') ?
                                        <>
                                          <p>En proceso. 🔵</p>
                                        </> :
                                        <>
                                          <p>En proceso. 🔴</p>
                                        </>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Asignado en depósito:</td>
                                <td>
                                  {
                                    (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                    <>
                                      <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                    </> :
                                    (Tracking.FinDepositario !== '0000-00-00 00:00:00') ?
                                      <>
                                        <p>
                                          {new Date(Tracking.FechaCreacion).toLocaleDateString('es-MX')} a las {Tracking.FechaCreacion.split(" ")[1]} 🟢
                                        </p>
                                      </> :                                      
                                      <>
                                        <p>En proceso. 🔴</p>
                                      </>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Verificado y embalado:</td>
                                <td>
                                  {
                                   (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                   <>
                                     <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                   </> :
                                   (Tracking.FinEmpacador !== '0000-00-00 00:00:00') ?
                                     <>
                                       <p>
                                         {new Date(Tracking.FechaCreacion).toLocaleDateString('es-MX')} a las {Tracking.FechaCreacion.split(" ")[1]} 🟢
                                       </p>
                                     </> :                                     
                                     <>
                                       <p>En proceso. 🔴</p>
                                     </>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Facturado:</td>
                                <td>
                                  {
                                    (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                    <>
                                      <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                    </> :
                                    (Tracking.Facturacion !== '0000-00-00 00:00:00') ?
                                      <>
                                        <p>
                                          {new Date(Tracking.FechaCreacion).toLocaleDateString('es-MX')} a las {Tracking.FechaCreacion.split(" ")[1]} 🟢
                                        </p>
                                      </> :                                      
                                      <>
                                        <p>En proceso. 🔴</p>
                                      </>
                                  }
                                </td>
                              </tr>                              
                              <tr>
                                <td>Carga subida al camión y Salida:</td>
                                <td>
                                  {
                                    (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                      <>
                                        <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                      </> :
                                      (Tracking.FechaSalida !== '0000-00-00 00:00:00' && Tracking.DocManifiesto !== '0000-00-00 00:00:00') ?
                                        <>
                                          <p>
                                            {new Date(Tracking.FechaSalida).toLocaleDateString('es-MX')} a las {Tracking.FechaSalida.split(" ")[1]} 🟢
                                          </p>
                                        </> :
                                        <>
                                          <p>En proceso. 🔴 </p>
                                        </>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Contiene:</td>
                                <td>
                                  {
                                    (Tracking.Cajas !== 0 || Tracking.Cajas !== undefined) ?
                                      <>
                                        <p><strong>{Tracking.Cajas} cajas</strong></p>
                                      </> :
                                      <>
                                        <p><strong>0 cajas</strong></p>
                                      </>
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Contiene:</td>
                                <td>
                                  {
                                    (Tracking.Bolsas !== 0) ?
                                      <>
                                        <p><strong>{Tracking.Bolsas} bolsas</strong></p>
                                      </> :
                                      <>
                                        <p><strong>0 bolsas</strong></p>
                                      </>
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                  </div>
                </div>

                <div className="division bg-belmeny"></div>

              </div>
            </div>

            {/* Body for mobile version */}
            <div className='d-block d-sm-none'>

<h5 className="bg-belmeny text-light rounded-pill text-center m-auto py-1 mb-3">Vendedor Encargado: {user.Nombre}</h5>
<div className="row badge-vendedor rounded-pill m-auto">
  <div className="col">
    <h3 className='text-center py-3 fs-2'>{Tracking.NombreCliente}</h3>
  </div>
</div>

<div className="container-fluid px-1 mx-auto">
  <div className="card px-2">
    <div className="row d-flex justify-content-between px-3 top">
      <div className="">
        <h3>
          Pedido:
          <span className="text-primary font-weight-bold"> {Tracking.Documento}</span>
        </h3>
      </div>
      <div className="d-flex flex-column text-sm-right">
        <h4>
          Seguimiento del pedido:
        </h4>
      </div>
    </div>

    <div className="row d-flex justify-content-center my-3">
      <div className="d-flex flex-column">
        <div className="connectbar-vertical"></div>

        {/* Pedido Creado */}
        <div className="row mb-5">
          <div className="col my-auto">
            <h6 className="text-center">Ingresado al sistema</h6>
          </div>
          {
            // ANULADO
            (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
              <>
                <div className="col">
                  <div className="progress-circle text-center align-middle m-auto d-flex">
                    <div className="my-auto mx-auto bg-light rounded-circle px-1">
                      <img src={pedidoCreadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                    </div>
                  </div>
                </div>
                <div className="col my-auto">
                  <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                </div>
              </> :
              // Finalizado
              (Tracking.FechaCreacion !== '0000-00-00 00:00:00' && Tracking.FinDepositario !== '0000-00-00 00:00:00') ?
                <>
                  <div className="col">
                    <div className="progress-circle text-center align-middle m-auto d-flex">
                      <div className="my-auto mx-auto bg-light rounded-circle px-1">
                        <img src={pedidoCreadoFinalizado} alt="" className="p-1" style={{ width: "65px" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col my-auto">
                    <h6 className="text-center">Finalizado 🟢</h6>
                  </div>
                </> :
                // En proceso
                (Tracking.FechaCreacion !== '0000-00-00 00:00:00' && Tracking.FinDepositario === '0000-00-00 00:00:00') ?
                  <>
                    <div className="col">
                      <div className="progress-circle text-center align-middle m-auto d-flex">
                        <div className="my-auto mx-auto bg-light rounded-circle px-1">
                          <img src={pedidoCreadoProceso} alt="" className="p-1" style={{ width: "65px" }} />
                        </div>
                      </div>
                    </div>
                    <div className="col my-auto">
                      <h6 className="text-center">En Proceso 🔵</h6>
                    </div>
                  </> :
                  // Pendiente
                  <>
                    <div className="col">
                      <div className="progress-circle text-center align-middle m-auto d-flex">
                        <div className="my-auto mx-auto bg-light rounded-circle px-1">
                          <img src={pedidoCreadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                        </div>
                      </div>
                    </div>
                    <div className="col my-auto">
                      <h6 className="text-center">Pendiente 🔴</h6>
                    </div>
                  </>
          }
        </div>

        {/* Despachado en almacen */}
        <div className="row my-5">
          <div className="col my-auto">
            <h6 className="text-center">Asignado en Almacen</h6>
          </div>
          {
            // ANULADO
            (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
              <>
                <div className="col">
                  <div className="progress-circle text-center align-middle m-auto d-flex">
                    <div className="my-auto mx-auto bg-light rounded-circle px-1">
                      <img src={almacenProcesadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                    </div>
                  </div>
                </div>
                <div className="col my-auto">
                  <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                </div>
              </> :
              // Finalizado
              (Tracking.FinDepositario !== '0000-00-00 00:00:00') ?
                <>
                  <div className="col">
                    <div className="progress-circle text-center align-middle m-auto d-flex">
                      <div className="my-auto mx-auto bg-light rounded-circle px-1">
                        <img src={almacenProcesadoFinalizado} alt="" className="p-1" style={{ width: "65px" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col my-auto">
                    <h6 className="text-center">Finalizado 🟢</h6>
                  </div>
                </> :                                    
                  // Pendiente
                  <>
                    <div className="col">
                      <div className="progress-circle text-center align-middle m-auto d-flex">
                        <div className="my-auto mx-auto bg-light rounded-circle px-1">
                          <img src={almacenProcesadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                        </div>
                      </div>
                    </div>
                    <div className="col my-auto">
                      <h6 className="text-center">Pendiente 🔴</h6>
                    </div>
                  </>
          }
        </div>

        {/* Verificado y Embalado */}
        <div className="row my-5">
          <div className="col my-auto">
            <h6 className="text-center">Verificado y Embalado</h6>
          </div>
          {
            // ANULADO
            (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
              <>
                <div className="col">
                  <div className="progress-circle text-center align-middle m-auto d-flex">
                    <div className="my-auto mx-auto bg-light rounded-circle px-1">
                      <img src={verificadoEmbaladoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                    </div>
                  </div>
                </div>
                <div className="col my-auto">
                  <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                </div>
              </> :
              // Finalizado
              (Tracking.FinEmpacador !== '0000-00-00 00:00:00') ?
                <>
                  <div className="col">
                    <div className="progress-circle text-center align-middle m-auto d-flex">
                      <div className="my-auto mx-auto bg-light rounded-circle px-1">
                        <img src={verificadoEmbaladoFinalizado} alt="" className="p-1" style={{ width: "65px" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col my-auto">
                    <h6 className="text-center">Finalizado 🟢</h6>
                  </div>
                </> :
                  // Pendiente
                  <>
                    <div className="col">
                      <div className="progress-circle text-center align-middle m-auto d-flex">
                        <div className="my-auto mx-auto bg-light rounded-circle px-1">
                          <img src={verificadoEmbaladoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                        </div>
                      </div>
                    </div>
                    <div className="col my-auto">
                      <h6 className="text-center">Pendiente 🔴</h6>
                    </div>
                  </>
          }
        </div>

        {/* Pedido Facturado */}
        <div className="row my-5">
          <div className="col my-auto">
            <h6 className="text-center">Facturado</h6>
          </div>
          {
            // ANULADO
            (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
              <>
                <div className="col">
                  <div className="progress-circle text-center align-middle m-auto d-flex">
                    <div className="my-auto mx-auto bg-light rounded-circle px-1">
                      <img src={pedidoFacturadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                </div>
              </> :
              // Finalizado
              (Tracking.Facturacion !== '0000-00-00 00:00:00') ?
                <>
                  <div className="col">
                    <div className="progress-circle text-center align-middle m-auto d-flex">
                      <div className="my-auto mx-auto bg-light rounded-circle px-1">
                        <img src={pedidoFacturadoFinalizado} alt="" className="p-1" style={{ width: "65px" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <h6 className="text-center">Finalizado 🟢</h6>
                  </div>
                </> :
                    // Pendiente
                    <>
                      <div className="col">
                        <div className="progress-circle text-center align-middle m-auto d-flex">
                          <div className="my-auto mx-auto bg-light rounded-circle px-1">
                            <img src={pedidoFacturadoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                          </div>
                        </div>
                      </div>
                      <div className="col my-auto">
                        <h6 className="text-center">Pendiente 🔴</h6>
                      </div>
                    </>
          }
        </div>


        {/* Salida a Reparto */}
        <div className="row my-5">
          <div className="col my-auto">
            <h6 className="text-center">Carga subida al camión y Salida:</h6>
          </div>
          {
            // ANULADO
            (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
              <>
                <div className="col">
                  <div className="progress-circle text-center align-middle m-auto d-flex">
                    <div className="my-auto mx-auto bg-light rounded-circle px-1">
                      <img src={salidaRepartoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                    </div>
                  </div>
                </div>
                <div className="col my-auto">
                  <h6 className="text-center text-danger fw-bold mt-2">**ANULADO**</h6>
                </div>
              </> :
              // Pendiente
              (Tracking.DocManifiesto !== "" && Tracking.FechaSalida !== '0000-00-00 00:00:00') ?
                <>
                  <div className="col">
                    <div className="progress-circle text-center align-middle m-auto d-flex">
                      <div className="my-auto mx-auto bg-light rounded-circle px-1">
                        <img src={salidaRepartoFinalizado} alt="" className="p-1" style={{ width: "65px" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col my-auto">
                    <h6 className="text-center">Finalizado 🟢</h6>
                  </div>
                </> :
                <>
                  <div className="col">
                    <div className="progress-circle text-center align-middle m-auto d-flex">
                      <div className="my-auto mx-auto bg-light rounded-circle px-1">
                        <img src={salidaRepartoPendiente} alt="" className="p-1" style={{ width: "65px" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col my-auto">
                    <h6 className="text-center mt-2">Pendiente 🔴</h6>
                  </div>
                </>
          }
        </div>

      </div>
    </div>

                  <div className="container-fluid">
                          <h6>Registro del  tracking:</h6>

                          <div className='list-container'>
                            <ol className="gradient-list">
                              <li>
                                <h6>Ingresado al sistema:</h6>
                                {
                                  (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                    <>
                                      <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                    </> :
                                    (Tracking.FechaCreacion !== '0000-00-00 00:00:00' && Tracking.FinDepositario !== '0000-00-00 00:00:00') ?
                                      <>
                                        <p>
                                          {new Date(Tracking.FechaCreacion).toLocaleDateString('es-MX')} a las {Tracking.FechaCreacion.split(" ")[1]} 🟢
                                        </p>
                                      </> :
                                      (Tracking.FechaCreacion !== '0000-00-00 00:00:00' && Tracking.FinDepositario === '0000-00-00 00:00:00') ?
                                      <>
                                        <p>En proceso. 🔵</p>
                                      </> :
                                      <>
                                        <p>En proceso. 🔴</p>
                                      </>
                                }
                              </li>

                              <li>
                                <h6>Asignado en depósito:</h6>
                                {
                                  (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                    <>
                                      <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                    </> :
                                    (Tracking.FinDepositario !== '0000-00-00 00:00:00') ?
                                      <>
                                        <p>
                                          {new Date(Tracking.FinDepositario).toLocaleDateString('es-MX')} a las {Tracking.FinDepositario.split(" ")[1]} 🟢
                                        </p>
                                      </> :
                                      <>
                                        <p>En proceso. 🔴</p>
                                      </>
                                }
                              </li>

                              <li>
                                <h6>Verificado y embalado:</h6>
                                {
                                  (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                    <>
                                      <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                    </> :
                                    (Tracking.FinEmpacador !== '0000-00-00 00:00:00')
                                      ?
                                      <>
                                        <p>
                                          {new Date(Tracking.FinEmpacador).toLocaleDateString('es-MX')} a las {Tracking.FinEmpacador.split(" ")[1]} 🟢
                                        </p>
                                      </> :
                                      <>
                                        <p>En proceso. 🔴 </p>
                                      </>
                                }
                              </li>

                              <li>
                                <h6>Facturado:</h6>
                                {
                                  (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                    <>
                                      <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                    </> :
                                    (Tracking.Facturacion !== '0000-00-00 00:00:00') ?
                                      <>
                                        <p>
                                          {new Date(Tracking.Facturacion).toLocaleDateString('es-MX')} a las {Tracking.Facturacion.split(" ")[1]} 🟢
                                        </p>
                                      </> :
                                      <>
                                        <p>En proceso. 🔴 </p>
                                      </>
                                }
                              </li>

                                                          <li>
                                <h6>Carga subida al camión y Salida:</h6>
                                {
                                  (Tracking.FechaAnulacion !== '0000-00-00 00:00:00') ?
                                    <>
                                      <p className='text-danger fw-bold'>🔴 **ANULADO** 🔴</p>
                                    </> :
                                    (Tracking.FechaSalida !== '0000-00-00 00:00:00' && Tracking.DocManifiesto !== '') ?
                                      <>
                                        <p>
                                          {new Date(Tracking.FechaSalida).toLocaleDateString('es-MX')} a las {Tracking.FechaSalida.split(" ")[1]} 🟢
                                        </p>
                                      </> :
                                      <>
                                        <p>En proceso. 🔴 </p>
                                      </>
                                }
                              </li>

                              <li><h6>Contiene:</h6> {(Tracking.Cajas !== 0 || Tracking.Cajas !== undefined) ? <><p>{Tracking.Cajas} cajas</p></> : <><p>0 cajas</p></>}</li>

                              <li><h6>Pagado:</h6> {(Tracking.Bolsas !== 0) ? <><p>{Tracking.Bolsas} bolsas</p></> : <><p>0 bolsas</p></>}</li>
                            </ol>
                          </div>
                        </div>
                </div>
                <div className="division bg-belmeny"></div>
              </div>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
