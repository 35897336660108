import React, { useState, useEffect } from 'react'

import { getVendedor } from '../../api/request';
import { getClienteRIF, getVentasClientes, getClientesVendedorFecha, getClientesVendedorFecha2, getClientesVendedorFecha3, getClientesVendedorFecha4 } from '../../api/requestClientes';

import Chart from "react-apexcharts";

import { formatNumber } from '../../utils/formats';

export const HistoricoCliente = ({ cliente }) => {

  const [loading, setLoading] = useState(true);

  const [ ventas2022, setVentas2022] = useState()
  const [ventas2023, setVentas2023] = useState()
  const [ventas2024, setVentas2024] = useState()
  const [ventas2025, setVentas2025] = useState()
  const [clienteData, setClienteData] = useState({})
  const [vendedorData, setVendedorData] = useState({})
  const [chartData, setChartData] = useState({});

  const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  function sortByMonth(arr) {
    arr.sort(function (a, b) {
      return labels.indexOf(a.months)
        - labels.indexOf(b.months);
    });
  }

  const loadHistoricoCliente = async () => {
    setLoading(true)
    const resClientData = await getClienteRIF(cliente)
    const clientData = resClientData[0]
    const resVendedorData = await getVendedor(clientData.Vendedor)
    const vendorData = resVendedorData[0]
    const respuesta = await getClientesVendedorFecha(clientData.Codigo);
      const respuesta2 = await getClientesVendedorFecha2(clientData.Codigo);
      const respuesta3 = await getClientesVendedorFecha3(clientData.Codigo);
      const respuesta4 = await getClientesVendedorFecha4(clientData.Codigo);
  
      
        const year = respuesta.year;
        const year2 = respuesta2.year;
        const year3 = respuesta3.year;
        const year4 = respuesta4.year;

    const data2022 = await getVentasClientes('2022', clientData.Codigo)
    const data2023 = await getVentasClientes('2023', clientData.Codigo)
    const data2024 = await getVentasClientes('2024', clientData.Codigo)
    if(year4===2025){
      const data2025 = await getVentasClientes('2025', clientData.Codigo)
      sortByMonth(data2025)
      const dataVentas2025 = labels.map(label => {
        const existingData = data2025.find(item => item.months.toLowerCase() === label.toLowerCase());
        if (existingData) {
          return existingData;
        } else {
          return { vendedor: '', months: label, ventas: 0, year: 2025 };
        }
      });
      setVentas2025(dataVentas2025)

      sortByMonth(data2022)
    sortByMonth(data2023)
    sortByMonth(data2024)

    const dataVentas2022 = labels.map(label => {
      const existingData = data2022.find(item => item.months.toLowerCase() === label.toLowerCase());
      if (existingData) {
        return existingData;
      } else {
        return { vendedor: '', months: label, ventas: 0, year: 2022 };
      }
    });

    const dataVentas2023 = labels.map(label => {
      const existingData = data2023.find(item => item.months.toLowerCase() === label.toLowerCase());
      if (existingData) {
        return existingData;
      } else {
        return { vendedor: '', months: label, ventas: 0, year: 2023 };
      }
    });

    const dataVentas2024 = labels.map(label => {
      const existingData = data2024.find(item => item.months.toLowerCase() === label.toLowerCase());
      if (existingData) {
        return existingData;
      } else {
        return { vendedor: '', months: label, ventas: 0, year: 2024 };
      }
    });

    

    setVentas2022(dataVentas2022)
    setVentas2023(dataVentas2023)
    setVentas2024(dataVentas2024)

    setClienteData(clientData)
    setVendedorData(vendorData)
    
    setChartData({
      options: {
        chart: {
          id: "chart-VentasAnuales",
          type: "area"
        },
        xaxis: {
          categories: labels
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        colors: ['#f28b05','#52d689', '#0041ff', '#d62915'],
        dataLabels: {
          enabled: false
        },
      },
      series: [
        {
          name: 'Ventas del año 2025 en USD',
          data: dataVentas2025.map((Ventas) => Ventas.ventas)
        },
        {
          name: 'Ventas del año 2024 en USD',
          data: dataVentas2024.map((Ventas) => Ventas.ventas)
        },
        {
          name: 'Ventas del año 2023 en USD',
          data: dataVentas2023.map((Ventas) => Ventas.ventas)
        },
        {
          name: 'Ventas del año 2022 en USD',
          data: dataVentas2022.map((Ventas) => Ventas.ventas)
        }
      ]
    })
    setLoading(false)
      
    } else{
      sortByMonth(data2022)
    sortByMonth(data2023)
    sortByMonth(data2024)

    const dataVentas2022 = labels.map(label => {
      const existingData = data2022.find(item => item.months.toLowerCase() === label.toLowerCase());
      if (existingData) {
        return existingData;
      } else {
        return { vendedor: '', months: label, ventas: 0, year: 2022 };
      }
    });

    const dataVentas2023 = labels.map(label => {
      const existingData = data2023.find(item => item.months.toLowerCase() === label.toLowerCase());
      if (existingData) {
        return existingData;
      } else {
        return { vendedor: '', months: label, ventas: 0, year: 2023 };
      }
    });

    const dataVentas2024 = labels.map(label => {
      const existingData = data2024.find(item => item.months.toLowerCase() === label.toLowerCase());
      if (existingData) {
        return existingData;
      } else {
        return { vendedor: '', months: label, ventas: 0, year: 2024 };
      }
    });

    

    setVentas2022(dataVentas2022)
    setVentas2023(dataVentas2023)
    setVentas2024(dataVentas2024)

    setClienteData(clientData)
    setVendedorData(vendorData)
    
    setChartData({
      options: {
        chart: {
          id: "chart-VentasAnuales",
          type: "area"
        },
        xaxis: {
          categories: labels
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        colors: ['#52d689', '#0041ff', '#d62915'],
        dataLabels: {
          enabled: false
        },
      },
      series: [
        {
          name: 'Ventas del año 2024 en USD',
          data: dataVentas2024.map((Ventas) => Ventas.ventas)
        },
        {
          name: 'Ventas del año 2023 en USD',
          data: dataVentas2023.map((Ventas) => Ventas.ventas)
        },
        {
          name: 'Ventas del año 2022 en USD',
          data: dataVentas2022.map((Ventas) => Ventas.ventas)
        }
      ]
    })
    setLoading(false)
    }

    
  }


  useEffect(() => {
    loadHistoricoCliente()
  }, [])

  return (
    <>
      {
        (loading) ?
          <>
            <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
              <div className="loader-book">
                <div>
                  <ul>
                    <li>
                      <svg fill="currentColor" viewBox="0 0 90 120">
                        <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                      </svg>
                    </li>
                    <li>
                      <svg fill="currentColor" viewBox="0 0 90 120">
                        <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                      </svg>
                    </li>
                    <li>
                      <svg fill="currentColor" viewBox="0 0 90 120">
                        <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                      </svg>
                    </li>
                    <li>
                      <svg fill="currentColor" viewBox="0 0 90 120">
                        <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                      </svg>
                    </li>
                    <li>
                      <svg fill="currentColor" viewBox="0 0 90 120">
                        <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                      </svg>
                    </li>
                    <li>
                      <svg fill="currentColor" viewBox="0 0 90 120">
                        <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                      </svg>
                    </li>
                  </ul>
                </div>
                <span>Cargando estadisticas...</span>
              </div>
            </div>
          </> :
          <div className="container-fluid">
            {/* Div para escritorio */}
            <div className='d-none d-md-block d-lg-block'>
              <div className="row">
                <div className="col">
                  <h2 className='text-center text-belmeny'>{clienteData.Nombre}</h2>
                  <div className="division w-25 m-auto my-3 bg-belmeny"></div>
                  <div className="text-center">
                    <h4 className='badge-vendedor rounded-pill text-light m-auto drop-shadow' style={{ width: "40%" }}>VENDEDOR ENCARGADO</h4>
                    <h3 className='bg-belmeny rounded-pill text-light fw-light m-auto drop-shadow' style={{ width: "60%" }}>{vendedorData.Nombre}</h3>
                  </div>
                  <ul className='mt-5'>
                    <li className='client-info'>
                      <h4><b>Telefono:</b> {clienteData.Telefono1}</h4>
                    </li>
                    <li className='client-info'>
                      <h4><b>Dirección Fiscal:</b> {clienteData.DireccionFiscal}</h4>
                    </li>
                    <li className='client-info'>
                      <h4><b>Limite:</b> ${clienteData.Limite}</h4>
                    </li>
                    <li className='client-info'>
                      <h4>
                        <b>Saldo Pendiente por Cobrar: </b>
                        {
                          (clienteData.SaldoPendiente > 0) ? <><span className='text-danger'>{formatNumber(clienteData.SaldoPendiente)} USD</span></> : <><span className="text-success">0 USD</span></>
                        }
                      </h4>
                    </li>
                  </ul>
                </div>
                <div className="col">
                  <h3 className='text-end text-belmeny me-3 fst-italic'>{clienteData.Rif}</h3>
                  {
                    (loading) ?
                      <>
                        <div className="text-center">
                          <div className="m-auto spinner">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </div>
                        </div>
                      </> :
                      <>
                        <Chart
                          options={chartData.options}
                          series={chartData.series}
                          type="area"
                          width="100%"
                          height="450px"
                        />
                      </>
                  }
                </div>
                 <h4 className='badge-vendedor rounded-pill text-light m-auto'>Detalle de ventas</h4>

                <div className="container">
                  <div className="row mt-3">
                    <div className="col-1"></div>
                    {
                      (labels.map((month) => <div className='col bg-belmeny3 rounded text-center mx-2'><span className="text-white fs-5">{month}</span></div>))
                    }
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <h5 className='text-belmeny mt-3'>2022</h5>
                    </div>
                    {(ventas2022.map((Ventas) => <div className='col text-center mt-3 text-belmeny mx-2'>{formatNumber(Ventas.ventas)} USD</div>))}
                  </div>
                  <div className="division mt-1 bg-belmeny"></div>
                  <div className="row">
                    <div className="col-1">
                      <h5 className='text-belmeny mt-3'>2023</h5>
                    </div>
                    {(ventas2023.map((Ventas) => <div className='col text-center mt-3 text-belmeny mx-2'>{formatNumber(Ventas.ventas)} USD</div>))}
                  </div>
                  <div className="division mt-1 bg-belmeny"></div>
                  <div className="row">
                    <div className="col-1">
                      <h5 className='text-belmeny mt-3'>2024</h5>
                    </div>
                    {(ventas2024.map((Ventas) => <div className='col text-center mt-3 text-belmeny mx-2'>{formatNumber(Ventas.ventas)} USD</div>))}
                  </div>
                  <div className="division mt-1 bg-belmeny"></div>
                  <div className="row">
                    <div className="col-1">
                      <h5 className='text-belmeny mt-3'>2025</h5>
                    </div>
                    {(ventas2025.map((Ventas) => <div className='col text-center mt-3 text-belmeny mx-2'>{formatNumber(Ventas.ventas)} USD</div>))}
                  </div>
                </div>

                <div className="division mt-3 bg-belmeny"></div>
                <h3 className="text-end text-belmeny fst-italic mt-2 pe-5">Belmeny Group C.A</h3> 
              </div>
            </div>

            {/* Div para móvil */}
            <div className='d-sm-block d-md-none'>
              <div className="row">
                <div className="row">
                  <h4 className='text-center text-belmeny fst-italic'>{clienteData.Rif}</h4>
                  <h3 className='text-center text-belmeny'>{clienteData.Nombre}</h3>
                  <div className="division w-50 m-auto my-3 bg-belmeny"></div>
                  <div className="text-center">
                    <h4 className='badge-vendedor rounded-pill text-light m-auto' style={{ width: "80%", position: "relative", left: "auto", top: "15%" }}>VENDEDOR ENCARGADO</h4>
                    <h3 className='bg-belmeny rounded-pill text-light fw-light m-auto pt-3' style={{ width: "100%" }}>{vendedorData.Nombre}</h3>
                  </div>
                </div>
                <ul className='mt-5'>
                  <li className='client-info'>
                    <h4><b>Telefono:</b> {clienteData.Telefono1}</h4>
                  </li>
                  <li className='client-info'>
                    <h4><b>Dirección Fiscal:</b> {clienteData.DireccionFiscal}</h4>
                  </li>
                  <li className='client-info'>
                    <h4><b>Limite:</b> ${clienteData.Limite}</h4>
                  </li>
                  <li className='client-info'>
                    <h4>
                      <b>Saldo Pendiente por Cobrar:</b>
                      {
                        (clienteData.SaldoPendiente > 0) ?
                          <span className='text-danger'>${formatNumber(clienteData.SaldoPendiente)}</span>
                          :
                          <span>${formatNumber(clienteData.SaldoPendiente)}</span>
                      }
                    </h4>
                  </li>
                </ul>
                <div className="division bg-belmeny my-3"></div>
                {
                  (loading) ?
                    <>
                      <div className="text-center">
                        <div className="m-auto spinner">
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </div>
                      </div>
                    </> :
                    <>
                      <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="area"
                        width="100%"
                        height="450px"
                      />
                    </>
                }
              </div>
            </div>
          </div>
      }
    </>
  )
}