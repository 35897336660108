import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import { AuthUser } from '../../../components/AuthUser';
import { Searchbar } from '../../../components/Searchbar';
import { DetalleTrackingSupervisor } from './DetalleTrackingSupervisor';

import { getTrackingxVendedor, getTrackingxZona, SearchTrackingSupervisor, SearchTrackingbyFechas, SearchTrackingSupervisorCliente, SearchTrackingbyFechasSupervisor } from '../../../api/requestTracking';
import { getZonasSupervisor } from '../../../api/requestSupervisor';
import { RequestPage } from '../../../api/request';
import Pagination from 'react-responsive-pagination';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { DateRangePicker, Stack } from 'rsuite';

import { startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek, subWeeks, format, addDays, subDays } from 'date-fns';

export const ConsultaTrackingSupervisor = () => {

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // States de filtros
  const [searchbarDoc, setSearchbarDoc] = useState('d-none')
  const [searchbarCli, setSearchbarCli] = useState('d-none')
  const [searchDate, setSearchDate] = useState('d-none')

  // States de busqueda
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [found, setFound] = useState(false);
  const [foundByFecha, setFoundByFecha] = useState(false);
  const [foundByCliente, setFoundByCliente] = useState(false);
  const [trackingFound, setTrackingFound] = useState([]);
  const [trackingFoundFecha, setTrackingFoundFecha] = useState([]);
  const [trackingFoundCliente, setTrackingFoundCliente] = useState([]);

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [prevPage, setPrevPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [lastPage, setLastPage] = useState('');

  // States para los datos
  const [Tracking, setTracking] = useState([]);
  const [zonasSupervisor, setZonasSupervisor] = useState([]);
  const [zonaSeleccionada, setZonaSeleccionada] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState([]);

  const [startActualMonth, setStartActualMonth] = useState(startOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss')
  const [endActualMonth, setEndActualMonth] = useState(endOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss')

  const predefinedRanges = [
    {
      label: 'Hoy',
      value: [new Date(), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Ayer',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'bottom'
    },
    {
      label: 'Esta semana',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'bottom'
    },
    {
      label: 'Últimos 7 días',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Últimos 30 días',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Mes actual',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Mes anterior',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Este año',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Último año',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'bottom'
    },
    {
      label: 'Desde el comienzo',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'bottom'
    },
    {
      label: 'Última semana',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Próxima semana',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];
  //funcion asincrona que carga los resultados del back
  useEffect(() => {
    loadTracking();
    setLoading(true)
  }, []);

  //funcion asincrona que recarga los resultados de la zona
  useEffect(() => {
    setCurrentPage(1); // Reset page to 1 when zonaSeleccionada changes
    handlePageChange(1); // Optionally load the data for the new zona on page 1
  }, [zonaSeleccionada]);
  
  //carga el tracking general
  const loadTracking = async () => {
    setLoading(true);
    const [resZonasSup, resTracking] = await Promise.all([getZonasSupervisor(user.CodVendedor), getTrackingxZona(user.CodVendedor, 'todas')])
    const res = await resTracking;
    setZonasSupervisor(await resZonasSup)
    setDataTracking(res)
    setLoading(false)
  }
  //carga el tracking por zona
  const loadTrackingZona = async (zona) => {
    setLoading(true);
    const resTracking = await getTrackingxZona(user.CodVendedor, zona)
    setZonaSeleccionada(zona)
    setDataTracking(resTracking)
    setLoading(false)
  }
  //carga la pagina, y lo hace a partir del numero de pagina
  const loadPage = async (page) => {
  setLoading(true);
  const trackingData = await RequestPage(page);  // Update URL with page number
  setDataTracking(trackingData);
  setLoading(false);
}
  //Obtiene la info de los tracking
  const setDataTracking = (dataTracking) => {
    var arrTracking = []
    var data = {}

    for (let i = 0; i < dataTracking.data.length; i++) {
      data = {
        Documento: dataTracking.data[i].Documento,
        CodVendedor: dataTracking.data[i].CodVendedor,
        NombreCliente: dataTracking.data[i].NombreCliente,
        FechaCreacion: dataTracking.data[i].FechaCreacion,
        FinDepositario: dataTracking.data[i].FinDepositario,
        FinEmpacador: dataTracking.data[i].FinEmpacador,
        Facturacion: dataTracking.data[i].Facturacion,
        FechaEnvio: dataTracking.data[i].FechaEnvio,
        FechaAnulacion: dataTracking.data[i].FechaAnulacion,
        FechaSalida: dataTracking.data[i].FechaSalida,
        Cajas: dataTracking.data[i].Cajas,
        Bolsas: dataTracking.data[i].Bolsas,
        Estado: dataTracking.data[i].Estado,
        Sector: dataTracking.data[i].Sector,
        NombreVendedor: dataTracking.data[i].Nombre
      }
      arrTracking.push(data)
    }

    setPage(dataTracking.current_page)
    setTotalPages(dataTracking.last_page)
    setNextPage(dataTracking.last_page_url)
    setPrevPage(dataTracking.first_page_url)
    setFirstPage(dataTracking.first_page_url)
    setLastPage(dataTracking.last_page_url)
    setUrlPage(dataTracking.links.first)

    setTracking(arrTracking)
  }
  //llamada cuando el usuario selecciona la pagina
  const handlePageChange = async (page) => {
    setLoading(true);
    
    // Assuming you have user.CodVendedor (which is actually CodSupervisor here), and zonaSeleccionada
    const codSupervisor = user.CodVendedor; // Assuming this is the CodSupervisor
    const zona = zonaSeleccionada !== '' ? zonaSeleccionada : 'todas'; // Default to "todas" if none selected
    // Construct the full URL with the correct parameters for pagination
    try {
    // Call getTrackingxZona to get the pagination data
    //const paginationData = await getTrackingxZona(codSupervisor, zona, page);

    // Extract the URL from the pagination data (assuming it's a field)
    //const url = paginationData.url; // Replace with the actual field name if different
      
    // llamada al get para obtener la ruta y calcular la informacion
    const trackingData = await getTrackingxZona(codSupervisor, zona, page);
    setDataTracking(trackingData);
    setCurrentPage(page);
  } catch (error) {
    console.error("Error loading page data:", error);
  }

  setLoading(false);
  }
  

  const onSearch = async (busqueda) => {
    if (searchbarCli === 'd-block') {
      if (!busqueda) {
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return loadTracking();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await SearchTrackingSupervisorCliente(user.CodVendedor, busqueda.toUpperCase());
      if (!result) {
        setNotFound(true);
        setLoading(false);
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return;
      } else {
        setFoundByCliente(true)
        setTrackingFoundCliente(result.data)
        setPage(1);
        setTotalPages(1);
      }
      setLoading(false);
      setSearching(false);
    }
    else if (searchbarDoc === 'd-block') {
      if (!busqueda) {
        setFound(false)
        return loadTracking();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await SearchTrackingSupervisor(user.CodVendedor,busqueda.toUpperCase());
      if (!result) {
        setNotFound(true);
        setLoading(false);
        setFound(false)
        return;
      } else {
        setFound(true)
        setTrackingFound(result.data)
        setPage(1);
        setTotalPages(1);
      }
      setLoading(false);
      setSearching(false);
    }
    else if (searchDate !== 'd-block') {
      setFoundByFecha(false)
    }
  };

  const changeDate = async (e) => {
    let date = JSON.stringify(e)
    let inicio = date.slice(2, 12) + " " + date.slice(14, 21)
    let fin = date.slice(29, 39) + " " + date.slice(41, 48)

    const res = await SearchTrackingbyFechasSupervisor(user.CodVendedor, inicio, fin)
    // console.log(res)
    if (res.length === 0) {
      setFound(false)
      setFoundByFecha(false)
    }
    else {
      setFound(false)
      setFoundByFecha(true)
      setTrackingFoundFecha(res)
    }
  }

  const showFilter = async (e, filtro) => {
    e.preventDefault();
    switch (filtro) {
      case 'Doc':
        setSearchbarDoc('d-block')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
      case 'Cliente':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-block')
        setSearchDate('d-none')
        break;
      case 'Fecha':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-block')
        break;

      case 'Limpiar':
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        setFoundByFecha(false)
        onSearch('')
        break;

      default:
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
    }
  }

  function format(inputDate) {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date
      .toString()
      .padStart(2, '0');

    month = month
      .toString()
      .padStart(2, '0');

    return `${date}/${month}/${year}`;
  }

  return (
    <>
      <Layout>
        {/* Cuerpo de página */}
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-tracking"></span>
              </div>
            </> :
            <>
              {/* Cabecera */}
              <div className="consultas-title pt-5">
                <h4 className='bg-belmeny text-light px-5 rounded-pill'>Tracking de Pedidos</h4>
              </div>

              <h6 className="text-center mt-2 mb-4 text-belmeny"><i>Usuario - {user.Nombre} - {user.CodGerente}</i></h6>

              {/* Selección de zona */}
              <div className='w-100 text-center mx-auto'>
                <button className='buttonMonths mx-2' onClick={() => loadTrackingZona('todas')}>
                  <span className="text-white">TODAS</span>
                </button>
                {
                  zonasSupervisor.map(zona => (
                    <button key={zona.Sector} className='buttonMonths mx-2' onClick={() => loadTrackingZona(zona.Sector)}>
                      <span className="text-white">{zona.Sector}</span>
                    </button>
                  ))
                }
                <h4 className='text-belmeny pt-3'>Zona Seleccionada: <span className="fst-italic">{zonaSeleccionada !== '' ? zonaSeleccionada : 'TODAS'}</span></h4>
              </div>
              {/* Desktop */}
              <div className="container-fluid d-md-block d-none mb-3">
                 <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                      <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente TODAS</Dropdown.Item>   
                       <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha TODAS</Dropdown.Item>   
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                      />
                    </Stack>
                  </div>
                </div> 
                <div className="rounded-0 my-2">
                  <table className='table table-bordered table-hover border-belmeny'>
                    <thead className='bg-belmeny text-light'>
                      <tr>
                        <th>Documento</th>
                        <th className='w-50'>Cliente</th>
                        <th>Zona</th>
                        <th>Vendedor</th>
                        <th>Fecha de ingreso al sistema</th>
                        <th>ANULADO</th>
                        <th style={{ width: "15%" }}>Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Busqueda del detalle del tracking */}
                      {
                        (found) ?
                          <>
                            {/* Encontrado unicamente por barra de busqueda */}
                            {trackingFound.map((item) => <DetalleTrackingSupervisor item={item} found={found} />)}
                          </> :
                          (foundByFecha) ?
                            <>
                              {/* Encontrado por fechas */}
                              {trackingFoundFecha.map((item) => <DetalleTrackingSupervisor item={item} found={found} foundFecha={foundByFecha} />)}
                            </> :
                            (foundByCliente) ?
                              <>
                                {/* Encontrado por cliente */}
                                {trackingFoundCliente.map((item) => <DetalleTrackingSupervisor item={item} found={found} foundFecha={foundByFecha} foundCliente={foundByCliente} />)}
                              </> :
                              <>
                                {/* Carga normal de todos los pedidos */}
                                {Tracking.map((item) => <DetalleTrackingSupervisor item={item} found={found} />)}
                              </>
                      }
                    </tbody>
                  </table>
                </div>
                      {/* Mostrar el resultado por pagination lo que nos permite seleccionar dependiendo del # de pagina*/}
                  {/* TotalPages es el total de paginas, Current la pagina actual y OnPaChange es el metodo de seleccion */}  
                  {
                  <Pagination
                    total={totalPages}
                    current={currentPage}
                    onPageChange={page => handlePageChange(page)}
                  />
                }
              </div>

              {/* Mobile */}
              <div className="container-fluid d-block d-sm-none">
                 <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Documentos `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente TODAS</Dropdown.Item> 
                        <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha TODAS</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                      />
                    </Stack>
                  </div>
                </div>

                {
                  (found) ?
                    <>
                      {/* Encontrado unicamente por barra de busqueda */}
                      {trackingFound.map((item) => <DetalleTrackingSupervisor item={item} found={found} />)}
                    </> :
                    (foundByFecha) ?
                      <>
                        {/* Encontrado por fechas */}

                        {trackingFoundFecha.map((item) => <DetalleTrackingSupervisor item={item} found={found} foundFecha={foundByFecha} />)}

                      </> :
                      (foundByCliente) ?
                        <>
                          {/* Encontrado por cliente */}
                          {trackingFoundCliente.map((item) => <DetalleTrackingSupervisor item={item} found={found} foundFecha={foundByFecha} foundCliente={foundByCliente} />)}
                        </> :
                        <>
                          {/* Carga normal de todos los pedidos */}
                          {Tracking.map((item) => <DetalleTrackingSupervisor item={item} found={found} />)}
                        </>
                }

{
                  (foundByFecha) ?
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </> :
                    <>
                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </>
                }
              </div>

              <div className="division bg-belmeny my-3 mx-auto" style={{ width: "95%" }}></div>
            </>
        }
      </Layout>
    </>
  )
}
