import axios from "axios";
import { baseURL } from "./ApiURL"
import { uniqueId } from "react-bootstrap-typeahead/types/utils";

/*
==================================================================================
CLIENTES
==================================================================================
*/
export async function getClientes(Vendedor) {
  try {
    const res = await axios.get(baseURL + `/getClientes?Vendedor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientesXLSX(Vendedor) {
  try {
    const res = await axios.get(baseURL + `/getClientesXLSX?Vendedor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

/*
==================================================================================
PETICIONES PARA QUE EL SUPERVISOR PUEDA VER TODOS LOS CLIENTES
==================================================================================
*/
export async function getAllClientes(CodSupervisor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getClientesxSupervisor?CodSupervisor=${CodSupervisor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientesAtendidosByGerente(fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getClientesAtendidosByGerente?&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function GetClienteRIF(Vendedor, rifCliente) {
  try {
    const res = await axios.get(baseURL + `/getClientes?Vendedor[eq]=${Vendedor}&Codigo[eq]=${rifCliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientesByVendedorAndRif(Vendedor, rifCliente) {
  try {
    const res = await axios.get(baseURL + `/getClientesByVendedorAndRif?Vendedor=${Vendedor}&Codigo=${rifCliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function SearchClienteRIF(Vendedor, rifCliente) {
  try {
    const res = await axios.get(baseURL + `/searchClientesbyRif?Vendedor=${Vendedor}&Codigo=${rifCliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function SearchClienteNombre(Vendedor, Cliente) {
  try {
    const res = await axios.get(baseURL + `/searchClientesbyNombre?Vendedor=${Vendedor}&Nombre=${Cliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClienteRIF(rifCliente) {
  try {
    const res = await axios.get(baseURL + `/getClientesRif?Codigo=${rifCliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientePorRif(Codigo, Vendedor) {
  try {
    const res = await axios.get(baseURL + `/getClientePorRif?Codigo=${Codigo}&Vendedor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientesVendedor(Vendedor) {
  try {
    const res = await axios.get(baseURL + `/getClientesVendedor?Vendedor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVentasClientes(year, codcliente) {
  try {
    const res = await axios.get(baseURL + `/getVentasClientes?year=${year}&codcliente=${codcliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVentasClienteIngco(year, vendedor) {
  try {
    const res = await axios.get(baseURL + `/getVentasClienteIngco?year=${year}&vendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVentasClienteVert(year, vendedor) {
  try {
    const res = await axios.get(baseURL + `/getVentasClienteVert?year=${year}&vendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVentasVendedorIngco(year, vendedor) {
  try {
    const res = await axios.get(baseURL + `/getVentasVendedorIngco?year=${year}&vendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVentasSupervisorIngco(year, vendedor) {
  try {
    const res = await axios.get(baseURL + `/getVentasSupervisorIngco?year=${year}&vendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVentasVendedorVert(year, vendedor) {
  try {
    const res = await axios.get(baseURL + `/getVentasVendedorVert?year=${year}&vendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getVentasSupervisorVert(year, vendedor) {
  try {
    const res = await axios.get(baseURL + `/getVentasSupervisorVert?year=${year}&vendedor=${vendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}


export async function getClientesVendedorFecha(codcliente) {
  try {
    const res = await axios.get(baseURL + `/getClientesVendedorFecha?codcliente=${codcliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientesVendedorFecha2(codcliente) {
  try {
    const res = await axios.get(baseURL + `/getClientesVendedorFecha2?codcliente=${codcliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientesVendedorFecha3(codcliente) {
  try {
    const res = await axios.get(baseURL + `/getClientesVendedorFecha3?codcliente=${codcliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientesVendedorFecha4(codcliente) {
  try {
    const res = await axios.get(baseURL + `/getClientesVendedorFecha4?codcliente=${codcliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}


export async function getClientesAtendidosByVendedor(CodigoVendedor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getClientesAtendidosByVendedor?CodigoVendedor=${CodigoVendedor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientesDesatendidosByVendedor(CodigoVendedor, loadDesatendidos) {
  try {
    const res = await axios.get(baseURL + `/getClientesDesatendidosByVendedor?CodigoVendedor=${CodigoVendedor}&loadDesatendidos=${loadDesatendidos}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getClientesDeudoresXLSX(CodigoVendedor) {
  try {
    const res = await axios.get(baseURL + `/getClientesDeudoresXLSX?CodigoVendedor=${CodigoVendedor}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getDetalleClientesAtendidosxVendedor(CodigoVendedor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/getDetalleClientesAtendidosxVendedor?CodigoVendedor=${CodigoVendedor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getClienteVentas6UltimosMeses(CodigoVendedor, codcliente) {
  try {
    const res = await axios.get(baseURL + `/getClienteVentas6UltimosMeses?CodigoVendedor=${CodigoVendedor}&codcliente=${codcliente}`);
    return res;
  } catch (error) {
    console.log(error)
  }
}

// Supervisor
export async function getClientesAtendidosxZona(CodSupervisor, fechaInicio, fechaFin, zona) {
  try {
    let res;
    if (fechaInicio !== undefined && fechaFin !== undefined && zona !== undefined) {
      res = await axios.get(baseURL + `/getClientesAtendidosxZona?CodSupervisor=${CodSupervisor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&Zona=${zona}`);
    } else {
      res = await axios.get(baseURL + `/getClientesAtendidosxZona?CodSupervisor=${CodSupervisor}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getTotalClientesxSupervisor(CodSupervisor, zona) {
  try {
    let res;
    if (zona !== undefined) {
      res = await axios.get(baseURL + `/getTotalClientesxSupervisor?CodSupervisor=${CodSupervisor}&Zona=${zona}`);
    } else {
      res = await axios.get(baseURL + `/getTotalClientesxSupervisor?CodSupervisor=${CodSupervisor}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getClientesAndZonaByCodigo(CodigoProducto, ZonasVenta) {
  try {
    const res = await axios.get(baseURL + `/getClientesAndZonaByCodigo?CodigoProducto=${CodigoProducto}&ZonasVenta=${ZonasVenta}`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getClientesAndZonaByNombre(NombreProducto, ZonasVenta) {
  try {
    const res = await axios.get(baseURL + `/getClientesAndZonaByNombre?NombreProducto=${NombreProducto}&ZonasVenta=${ZonasVenta}`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getClientesByZonaCodigoAndTerm(CodigoProducto, Zona, CodUsuario, ZonasVenta) {
  try {
    const res = await axios.get(baseURL + `/getClientesByZonaCodigoAndTerm?CodigoProducto=${CodigoProducto}&Zona=${Zona}&CodUsuario=${CodUsuario}&ZonasVenta=${ZonasVenta}`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getClientesByZonaNombreAndTerm(NombreProducto, Zona, CodUsuario, ZonasVenta) {
  try {
    const res = await axios.get(baseURL + `/getClientesByZonaNombreAndTerm?NombreProducto=${NombreProducto}&Zona=${Zona}&CodUsuario=${CodUsuario}&ZonasVenta=${ZonasVenta}`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

export async function getZonasClientes() {
  try {
    const res = await axios.get(baseURL + `/getZonasClientes`)
    return res.data;
  } catch (error) {
    console.error(error)
  }
}