import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';
import { PedidosPorDiaTable } from '../../components/PedidosPorDiaTable';
import { CorteSemanal } from '../../components/modulos_gerente/CorteSemanal';
import { RankingVendedores } from '../../components/modulos_gerente/RankingVendedores';
import { CobranzasZona } from '../../components/modulos_gerente/CobranzasZona';
import { TopClientes } from '../../components/modulos_gerente/TopClientes';
import { TopProductos } from '../../components/modulos_gerente/TopProductos';
import { ModulosGerente } from '../../data/menuData';

import belmenyLogo from '../../assets/img/logoBG.webp';
import otherUserIcon from '../../assets/img/user-icon-resize.webp';
import notFoundImg from '../../assets/img/404-module-not-found-2.webp';
import { CircleUserRound, ChevronRight, TrendingUp, TrendingDown } from 'lucide-react';
import { RiCloseCircleFill } from "react-icons/ri";

import { getVentasAnualesEnCurso } from '../../api/request';
import { getTotalClientesxSupervisor, getClientesAtendidosxZona, getClientesAtendidosByGerente } from '../../api/requestClientes';
import { getZonasSupervisor, getCorteSemanalxZona, getVentasZona, getCantidadVentasxGerente, getTop10Vendedores, getFPAPByZona, getPedidosPorVendedor, getCobrosAnualesEnCurso, getFacturasVencidas, getFacturasEmitidas, getTopClientes, getFacturadoAndCobradoHoyVsAyer, getParetos, getPorcentajeSupervisores, getPorcentajeGerente } from '../../api/requestSupervisor';

import 'react-bootstrap-typeahead/css/Typeahead.css';

import axios from 'axios';

import Chart from "react-apexcharts";
import { DateRangePicker, Stack } from 'rsuite';

import { startOfMonth, endOfMonth, addMonths, format } from 'date-fns';

import { formatNumber } from '../../utils/formats';

// SVG LOGOS
import brokenImg from '../../assets/img/broken-image-general.png';
import belmenySvg from '../../assets/img/logos/BELMENY-SVG.svg'
import ingcoSvg from '../../assets/img/logos/INGCO-SVG.svg'
import vertSvg from '../../assets/img/logos/VERT-SVG.svg'
import quilosaSvg from '../../assets/img/logos/QUILOSA-SVG.svg'
import fleximaticSvg from '../../assets/img/logos/FLEXIMATIC-SVG.svg'
import imouSvg from '../../assets/img/logos/IMOU-SVG.svg'

// JPG LOGOS
import belmenyJpg from '../../assets/img/logos/BELMENY-JPG.jpg'
import ingcoJpg from '../../assets/img/logos/INGCO-JPG.jpg'
import vertJpg from '../../assets/img/logos/VERT-JPG.jpg'
import quilosaJpg from '../../assets/img/logos/QUILOSA-JPG.jpg'
import fleximaticJpg from '../../assets/img/logos/FLEXIMATIC-JPG.jpg'
import imouJpg from '../../assets/img/logos/IMOU-JPG.jpg'

export const DashboardGerente = () => {

  useEffect(() => {
    loadVentas()
    CheckImg()
  }, []);

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalLoading, setModalLoading] = useState("");

  // State de datos
  const [username, setUsername] = useState('');
  const [userIcon, setUserIcon] = useState(``);
  const [vendedor, setVendedor] = useState('No se ha seleccionado un vendedor...');
  const [zonaSelected, setZonaSelected] = useState('General');
  const [totalMeta, setTotalMeta] = useState(0);
  const [totalFacturado, setTotalFacturado] = useState(0);
  const [totalClientes, setTotalClientes] = useState(0);
  const [facturasEmitidas, setFacturasEmitidas] = useState(0);
  const [resumenFacturado, setResumenFacturado] = useState(0);
  const [resumenPedido, setResumenPedido] = useState(0);
  const [zonasSupervisor, setZonasSupervisor] = useState([]);
  const [ventasSemanales, setVentasSemanales] = useState([]);
  const [ventasGralZona, setVentasGralZona] = useState([]);
  const [ventasProductos, setVentasProductos] = useState([]);
  const [top10Vendedores, setTop10Vendedores] = useState([]);
  const [fpapData, setFpapData] = useState([]);
  const [pedidosPorDia, setPedidosPorDia] = useState([]);
  const [facturadoHoyVsAyer, setFacturadoHoyVsAyer] = useState({})
  const [cobradoHoyVsAyer, setCobradoHoyVsAyer] = useState({})
  const [ventasAnualesData, setVentasAnualesData] = useState({});
  const [ventasZonaData, setVentasZonaData] = useState({});
  const [facturasVencidas, setFacturasVencidas] = useState({});
  const [topClientes, setTopClientes] = useState({});
  const [vxcChart, setVxcChart] = useState({});
  const [top10Chart, setTop10Chart] = useState({});
  const [clientesChart, setClientesChart] = useState({});
  const [porcentajeGerente, setPorcentajeGerente] = useState({});
  const [porcentajeSupervisores, setPorcentajeSupervisores] = useState({});
  const [porcentajeGerente2, setPorcentajeGerente2] = useState({});
  const [porcentajeSupervisores2, setPorcentajeSupervisores2] = useState({});
  
  // Use the values in your state or wherever you need them
  const [startActualMonth, setStartActualMonth] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'))
  const [endActualMonth, setEndActualMonth] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss'))

  const flete = 0.031

  const predefinedRanges = [
    {
      label: 'Últimos 3 meses',
      value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Últimos 6 meses',
      value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Último año',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'bottom'
    }
  ];

  const { afterToday } = DateRangePicker;

  const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  function sortByMonth(arr) {
    var months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
      "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    arr.sort(function (a, b) {
      return months.indexOf(a.mes)
        - months.indexOf(b.mes);
    });
  }

  const handleImageError = (event) => {
    event.target.src = brokenImg;
  };

  const handleButtonClick = (buttonId) => {
    setShow(true);
    switch (buttonId) {
      case 'pedidosPorDia':
        setModalTitle("Resumen de pedidos por día")
        setModalBody("pedidosPorDia")
        setModalLoading("resumen de pedidos por día")
        break;
      case 'corteSemanal':
        setModalTitle("Corte semanal")
        setModalBody("corteSemanal")
        setModalLoading("corte semanal")
        break;
      case 'ranking':
        setModalTitle("Top 10 vendedores del mes")
        setModalBody("rankingVendedores")
        setModalLoading("top de vendedores")
        break;
      case 'cobranzasZona':
        setModalTitle("Cobranzas por Zona")
        setModalBody("cobranzasZona")
        setModalLoading("cobranzas por zona")
        break;
      case 'topClientes':
        setModalTitle("Top Clientes")
        setModalBody("topClientes")
        setModalLoading("top de clientes")
        break;
      case 'topProductos':
        setModalTitle("Top Productos")
        setModalBody("topProductos")
        setModalLoading("top de productos")
        break;
      default:
        setModalTitle("Resumen de pedidos por día")
        setModalBody("pedidosPorDia")
        setModalLoading("resumen de pedidos por día")
        break;
    }
  };

  const changeDate = async (e) => {
    setLoading(true)
    let date = JSON.stringify(e)
    let start = date.slice(2, 12) + " 00:00:00"
    let end = date.slice(29, 39) + " 23:59:59"

    let resCalendar;

    if (zonaSelected === 'General') {
      resCalendar = await getPedidosPorVendedor(start, end, zonaSelected)
    } else {
      resCalendar = await getPedidosPorVendedor(start, end, zonaSelected)
    }

    // Define your desired date range based on start and end dates
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Create an array of dates within the date range
    const dateRange = [];
    for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
      dateRange.push(currentDate.toISOString().slice(0, 10));
    }

    // Create a set of unique dates from your data
    const uniqueDates = new Set(resCalendar.pedidosPorVendedor.map(entry => entry.Fecha));

    // Create a list of vendors
    const uniqueVendors = new Map();
    // Create a mapping of Vendedor or CodVendedor to their corresponding data
    const totalPedidoMap = new Map();
    const totalFacturadoMap = new Map();
    const countPedidosMap = new Map();
    const sectoresMap = new Map();

    resCalendar.nroPedidos.forEach(item => {
      countPedidosMap.set(item.Vendedor, item.nroPedidos);
    })

    resCalendar.pedidosPorVendedor.forEach(item => {
      sectoresMap.set(item.CodVendedor, item.Sector);
    })

    resCalendar.nroPedidos.forEach(item => {
      totalPedidoMap.set(item.Vendedor, item.TotalPedido, item.nroPedidos);
    });

    resCalendar.nroFacturas.forEach(item => {
      totalFacturadoMap.set(item.CodigoVendedor, item.TotalFacturado);
    });

    // Add TotalPedido and TotalFacturado to each item in pedidosPorVendedor
    const updatedPedidosPorVendedor = resCalendar.pedidosPorVendedor.map(item => {
      const codVendedor = item.CodVendedor;

      return {
        ...item,
        sectores: sectoresMap.get(codVendedor) || 0,
        nroPedidos: countPedidosMap.get(codVendedor) || 0,
        TotalPedido: totalPedidoMap.get(codVendedor) || 0,
        TotalFacturado: totalFacturadoMap.get(codVendedor) || 0,
      };
    });

    // Iterate over vendors and dates to fill in missing entries
    updatedPedidosPorVendedor.forEach(vendor => {
      // If the vendor is not already in the map, add it
      if (!uniqueVendors.has(vendor.CodVendedor)) {
        uniqueVendors.set(vendor.CodVendedor, { CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado, sectores: vendor.Sector });
      }
    });

    // Create a new array to store the filled data
    const filledData = [];

    // Iterate over unique vendors and dates to fill in missing entries
    uniqueVendors.forEach(vendor => {
      dateRange.forEach(date => {
        const existingEntry = updatedPedidosPorVendedor.find(entry => entry.CodVendedor === vendor.CodVendedor && entry.Fecha === date);
        if (existingEntry) {
          filledData.push({ Pedidos: existingEntry.Pedidos, CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, Fecha: date, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado, sectores: vendor.sectores });
        } else {
          // If the entry is missing, add it with 0 orders
          filledData.push({ Pedidos: 0, CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, Fecha: date, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado, sectores: vendor.sectores });
        }
      });
    });

    // Sort the filledData array by 'CodVendedor' (vendor code) and then by 'Fecha' (date)
    filledData.sort((a, b) => {
      if (a.CodVendedor !== b.CodVendedor) {
        return a.CodVendedor.localeCompare(b.CodVendedor); // Sort by vendor code
      } else {
        const dateA = new Date(a.Fecha);
        const dateB = new Date(b.Fecha);
        return dateA - dateB; // Sort by date
      }
    });

    setPedidosPorDia(filledData);
    const totalFactSinFlete = resCalendar.nroFacturas.reduce((acc, factura) => acc + factura.TotalFacturado, 0) - (resCalendar.nroFacturas.reduce((acc, factura) => acc + factura.TotalFacturado, 0) * flete)
    const totalPedSinFlete = resCalendar.nroPedidos.reduce((acc, factura) => acc + factura.TotalPedido, 0) - (resCalendar.nroPedidos.reduce((acc, factura) => acc + factura.TotalPedido, 0) * flete)
    setResumenFacturado(totalFactSinFlete);
    setResumenPedido(totalPedSinFlete);

    setLoading(false)
  }

  const CheckImg = async () => {
    await axios.get(`https://belmeny.com/fotos_vendedores/${user.CodVendedor}.jpeg`).then(() => {
      setUserIcon(`https://belmeny.com/fotos_vendedores/${user.CodVendedor}.jpeg`)
      return true;
    })
      .catch(() => {
        setUserIcon(otherUserIcon)
        return false;
      });
  }

  const loadVentas = async () => {
    setUsername(user.CodGerente)
    const porcentajeSupervisores= await getPorcentajeSupervisores()
    const porcentajeSupervisores2= await getClientesAtendidosByGerente(startActualMonth, endActualMonth)
    const porcentajeSupervisores3= await getPorcentajeSupervisores()
    setPorcentajeGerente(await getPorcentajeGerente())
    const [resTop10Vendedores, resZonasSup, resCorteSemanal, resVentasxZona, resClientesFacturados, resTotalClientes, resFpap, resCalendar, resVentasAñoPasado, resVentasAnuales, resCobrosAnuales, resFacturasVencidas, resFacturasEmitidas, resTopClientes, resVxC, resFacturadoHoyVsAyer] = await Promise.all([getTop10Vendedores(), getZonasSupervisor(user.CodGerente), getCorteSemanalxZona(user.CodVendedor, 'si'), getVentasZona(user.CodGerente), getClientesAtendidosxZona(user.CodGerente), getTotalClientesxSupervisor(user.CodGerente), getFPAPByZona(startActualMonth, endActualMonth), getPedidosPorVendedor(startActualMonth, endActualMonth, zonaSelected), getVentasAnualesEnCurso(user.CodGerente, 2024), getVentasAnualesEnCurso(user.CodGerente), getCobrosAnualesEnCurso(), getFacturasVencidas(), getFacturasEmitidas(), getParetos(null, null, "Facturado"), getCantidadVentasxGerente(user.CodGerente), getFacturadoAndCobradoHoyVsAyer()])

    // Combina ambos arreglos emparejando por "CodSupervisor"
    const { listaClientes, atendidos } = porcentajeSupervisores2;

    const combinedData = listaClientes.map(cliente => {
      const atendido = atendidos.find(a => a.CodSupervisor === cliente.CodSupervisor);
      return {
        NombreSupervisor: cliente.NombreSupervisor,
        TotalClientes: cliente.TotalClientes,
        TotalClientesAtendidos: atendido ? atendido.TotalClientesAtendidos : 0,
        // Si necesitas el porcentaje:
        porcentajeAtendidos: atendido ? (atendido.TotalClientesAtendidos / cliente.TotalClientes) * 100 : 0
      };
    });

    const zonasSup = await resZonasSup;
    const ventasxZona = await resVentasxZona;
    const corteSemanal = await resCorteSemanal;
    const clientesFacturados = await resClientesFacturados;
    const totClientes = await resTotalClientes;
    const top10Vend = await resTop10Vendedores;
    const fpap = await resFpap
    const calendar = await resCalendar
    const ventasAñoPasado = await resVentasAñoPasado;
    const ventasAnuales = await resVentasAnuales;
    const cobrosAnuales = await resCobrosAnuales;
    const facturasVencidas = await resFacturasVencidas;
    const facturasEmitidas = await resFacturasEmitidas;
    const topClientes = await resTopClientes;
    const vxc = await resVxC
    const facturasCobrosHoyVsAyer = await resFacturadoHoyVsAyer

    setZonasSupervisor(zonasSup);
    setVentasSemanales(corteSemanal);
    setVentasGralZona(ventasxZona);
    setTotalClientes(totClientes);
    setTop10Vendedores(top10Vend);
    setFpapData(fpap);
    setFacturasVencidas(facturasVencidas)
    setFacturasEmitidas(facturasEmitidas.FacturasEmitidas)
    setTopClientes(topClientes)
    console.log(topClientes)
    setVentasProductos(vxc)
    setFacturadoHoyVsAyer({
      "hoy": facturasCobrosHoyVsAyer[0].FacturadoHoy ?? 0,
      "ayer": facturasCobrosHoyVsAyer[1].FacturadoAyer ?? 0,
      "hoy30Dias": facturasCobrosHoyVsAyer[2].FacturadoHoy30 ?? 0,
      "ayer30Dias": facturasCobrosHoyVsAyer[3].FacturadoAyer30 ?? 0
    })
    setCobradoHoyVsAyer({
      "hoy": facturasCobrosHoyVsAyer[4].CobradoHoy ?? 0,
      "ayer": facturasCobrosHoyVsAyer[5].CobradoAyer ?? 0,
      "hoy30Dias": facturasCobrosHoyVsAyer[6].CobradoHoy30 ?? 0,
      "ayer30Dias": facturasCobrosHoyVsAyer[7].CobradoAyer30 ?? 0
    })

    setPorcentajeSupervisores({
      options: {
        chart: {
          id: "chart-porcentajeSupervisores",
          type: "bar"
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        xaxis: {
          categories: porcentajeSupervisores.map((cliente) => cliente.Nombre)
        },
        colors: ['#fba10b','#0041ff'],
        
      },
      series: [{
        name: 'Porcentaje de compra de INGCO',
        data: porcentajeSupervisores.map((cliente) => cliente.porcentaje_ingco)
      },
      {
        name: 'Porcentaje de compra de VERT',
        data: porcentajeSupervisores.map((cliente) => cliente.porcentaje_vert)
      }
    ]
    })

    setPorcentajeSupervisores2({
      options: {
        chart: {
          id: "chart-porcentajeSupervisores",
          type: "bar"
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        xaxis: {
          categories: combinedData.map(item => item.NombreSupervisor)
        },
        colors: ['#0041ff','#27ae60b3','#ff0000b3'],
      },
      series: [
        {
          name: 'Clientes Activos',
          data: combinedData.map(item => item.TotalClientes)
        },
        {
          name: 'Clientes Atendidos',
          data: combinedData.map(item => item.TotalClientesAtendidos)
        },
        {
          name: 'Clientes Desatendidos',
          data: combinedData.map(item => item.TotalClientes - item.TotalClientesAtendidos)
        }
      ]
    });
    

    // Create a set of unique dates
    const uniqueDates = new Set(calendar.pedidosPorVendedor.map(entry => entry.Fecha));

    // Create a list of vendors
    const uniqueVendors = new Map();
    // Create a mapping of Vendedor or CodVendedor to their corresponding data
    const totalPedidoMap = new Map();
    const totalFacturadoMap = new Map();
    const countPedidosMap = new Map();
    const sectoresMap = new Map();

    resCalendar.nroPedidos.forEach(item => {
      countPedidosMap.set(item.Vendedor, item.nroPedidos);
    })

    resCalendar.pedidosPorVendedor.forEach(item => {
      sectoresMap.set(item.CodVendedor, item.Sector);
    })

    calendar.nroPedidos.forEach(item => {
      totalPedidoMap.set(item.Vendedor, item.TotalPedido);
    });

    calendar.nroFacturas.forEach(item => {
      totalFacturadoMap.set(item.CodigoVendedor, item.TotalFacturado);
    });

    // Add TotalPedido and TotalFacturado to each item in pedidosPorVendedor
    const updatedPedidosPorVendedor = resCalendar.pedidosPorVendedor.map(item => {
      const codVendedor = item.CodVendedor;

      return {
        ...item,
        sectores: sectoresMap.get(codVendedor) || 0,
        nroPedidos: countPedidosMap.get(codVendedor) || 0,
        TotalPedido: totalPedidoMap.get(codVendedor) || 0,
        TotalFacturado: totalFacturadoMap.get(codVendedor) || 0,
      };
    });

    // Iterate over vendors and dates to fill in missing entries
    updatedPedidosPorVendedor.forEach(vendor => {
      // If the vendor is not already in the map, add it
      if (!uniqueVendors.has(vendor.CodVendedor)) {
        uniqueVendors.set(vendor.CodVendedor, { CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado, sectores: vendor.Sector });
      }
    });

    // Create a new array to store the filled data
    const filledData = [];

    // Iterate over unique vendors and dates to fill in missing entries
    uniqueVendors.forEach(vendor => {
      uniqueDates.forEach(date => {
        const existingEntry = updatedPedidosPorVendedor.find(entry => entry.CodVendedor === vendor.CodVendedor && entry.Fecha === date);
        if (existingEntry) {
          filledData.push({ Pedidos: existingEntry.Pedidos, CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, Fecha: date, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado, sectores: vendor.sectores });
        } else {
          // If the entry is missing, add it with 0 orders
          filledData.push({ Pedidos: 0, CodVendedor: vendor.CodVendedor, Nombre: vendor.Nombre, Fecha: date, nroPedidos: vendor.nroPedidos, TotalPedido: vendor.TotalPedido, TotalFacturado: vendor.TotalFacturado, sectores: vendor.sectores });
        }
      });
    });

    // Sort the filledData array by 'CodVendedor' (vendor code) and then by 'Fecha' (date)
    filledData.sort((a, b) => {
      if (a.CodVendedor !== b.CodVendedor) {
        return a.CodVendedor.localeCompare(b.CodVendedor); // Sort by vendor code
      } else {
        const dateA = new Date(a.Fecha);
        const dateB = new Date(b.Fecha);
        return dateA - dateB; // Sort by date
      }
    });

    setPedidosPorDia(filledData);
    const totalFactSinFlete = resCalendar.nroFacturas.reduce((acc, factura) => acc + factura.TotalFacturado, 0) - (resCalendar.nroFacturas.reduce((acc, factura) => acc + factura.TotalFacturado, 0) * flete)
    const totalPedSinFlete = resCalendar.nroPedidos.reduce((acc, factura) => acc + factura.TotalPedido, 0) - (resCalendar.nroPedidos.reduce((acc, factura) => acc + factura.TotalPedido, 0) * flete)
    setResumenFacturado(totalFactSinFlete);
    setResumenPedido(totalPedSinFlete);

    // Sumatoria total de metas
    let metasSum = 0
    let facturadoSum = 0
    for (let i = 0; i < ventasxZona.length; i++) {
      metasSum += ventasxZona[i].meta_zona;
      facturadoSum += (ventasxZona[i].total_vendido)
    }
    setTotalMeta(metasSum)
    setTotalFacturado(facturadoSum)

    const ventaAñoPasado = labels.map(label => (ventasAñoPasado[label] === null) ? 0 : ventasAñoPasado[label].toFixed(2));
    const ventaActualYear = labels.map(label => (ventasAnuales[label] === null) ? 0 : ventasAnuales[label].toFixed(2));
    const cobroActualYear = labels.map(label => (cobrosAnuales[label] === null) ? 0 : cobrosAnuales[label].toFixed(2));

    // Area chart de ventas del año actual
    setVentasAnualesData({
      options: {
        chart: {
          id: "chart-VentasAnuales",
          type: "area"
        },
        xaxis: {
          categories: labels
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        colors: ['#52d689', '#0041ff', '#d62915'],
        dataLabels: {
          enabled: false
        },
      },
      series: [
        {
          name: 'Ventas en USD del año pasado',
          data: ventaAñoPasado
        },
        {
          name: 'Ventas en USD del año actual',
          data: ventaActualYear
        },
        {
          name: 'Cobros en USD del año actual',
          data: cobroActualYear
        }
      ]
    })

    // Bar chart ventas del mes x zona
    setVentasZonaData({
      options: {
        chart: {
          id: "chart-VentasZona",
        },
        xaxis: {
          categories: ventasxZona.map((Zona) => `${Zona.Sector} - ${Zona.meta_zona} USD`)
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        colors: ['#27ae60'],
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals
            if (goals && goals.length) {
              return `${((val * 100) / goals[0].value).toFixed(2)}%`
            }
            return val
          },
          enabled: true,
          style: {
            colors: ['#000']
          }
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          customLegendItems: ['Ventas actuales', 'Ventas esperadas'],
          markers: {
            fillColors: ['#27ae60', '#e74c3c']
          }
        }
      },
      series: [
        {
          name: 'Ventas en USD',
          data: ventasxZona.map((Zona) => ({
            x: Zona.Sector,
            y: ((Zona.total_vendido) - (Zona.total_vendido * 0.031)).toFixed(2),
            goals: [
              {
                name: 'Meta en USD',
                value: Zona.meta_zona,
                strokeWidth: 5,
                strokeHeight: 5,
                strokeLineCap: 'round',
                strokeColor: '#e74c3c'
              }
            ]
          }))
        }
      ]
    })

    // Donut chart de clientes facturados en el mes
    setClientesChart({
      options:
      {
        labels: clientesFacturados.map((Zona) => `${Zona.Sector}`), // Replace with your own labels
        legend: {
          show: true,
          position: "bottom",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: true
              }
            },
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      series: clientesFacturados.map((Zona) => Zona.uniqueClientes)
    })

    const sumCantidadProductosVert = vxc.reduce((acc, productos) => {
      const cantidadProductos = productos.CantidadProductosVert;
      return typeof cantidadProductos === 'number' ? acc + cantidadProductos : acc + 0;
    }, 0);

    const sumCantidadProductosIngco = vxc.reduce((acc, productos) => {
      const cantidadProductos = productos.CantidadProductosIngco;
      return typeof cantidadProductos === 'number' ? acc + cantidadProductos : acc + 0;
    }, 0);

    const sumCantidadProductosImou = vxc.reduce((acc, productos) => {
      const cantidadProductos = productos.CantidadProductosImou;
      return typeof cantidadProductos === 'number' ? acc + cantidadProductos : acc + 0;
    }, 0);

    const sumCantidadProductosWadfow = vxc.reduce((acc, productos) => {
      const cantidadProductos = productos.CantidadProductosWadfow;
      return typeof cantidadProductos === 'number' ? acc + cantidadProductos : acc + 0;
    }, 0);

    const sumCantidadProductosQuilosa = vxc.reduce((acc, productos) => {
      const cantidadProductos = productos.CantidadProductosQuilosa;
      return typeof cantidadProductos === 'number' ? acc + cantidadProductos : acc + 0;
    }, 0);

    const sumCantidadProductosFleximatic = vxc.reduce((acc, productos) => {
      const cantidadProductos = productos.CantidadProductosFleximatic;
      return typeof cantidadProductos === 'number' ? acc + cantidadProductos : acc + 0;
    }, 0);

    const sumCantidadProductosCorona = vxc.reduce((acc, productos) => {
      const cantidadProductos = productos.CantidadProductosCorona;
      return typeof cantidadProductos === 'number' ? acc + cantidadProductos : acc + 0;
    }, 0);

    const sumCantidadProductosPOP = vxc.reduce((acc, productos) => {
      const cantidadProductos = productos.CantidadProductosPOP;
      return typeof cantidadProductos === 'number' ? acc + cantidadProductos : acc + 0;
    }, 0);

    setVxcChart({
      options:
      {
        labels: ["Vërt", "Ingco", "Imou", "Wadfow", "Quilosa", "Fleximatic", "Corona", "POP"],
        legend: {
          show: true,
          position: "bottom",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: false
              }
            },
            dataLabels: {
              enabled: true,
            },
          },
        },
        colors: ['#0041ff', '#fba10b', '#F18D00', '#0033A1', '#001242', '#001242', '#0069B4', '#002975']
      },
      series: [sumCantidadProductosVert, sumCantidadProductosIngco, sumCantidadProductosImou, sumCantidadProductosWadfow, sumCantidadProductosQuilosa, sumCantidadProductosFleximatic, sumCantidadProductosCorona, sumCantidadProductosPOP]
    })

    // Top 10 vendedores del mes
    setTop10Chart({
      options: {
        chart: {
          id: "chart-VentasZona"
        },
        xaxis: {
          categories: top10Vend.map((Zona) => `${Zona.CodigoVendedor} ${Zona.Nombre}`)
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          }
        },
        colors: ['#005bc5'],
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals
            if (goals && goals.length) {
              return `${val}`
            }
            return val
          },
          enabled: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          customLegendItems: ['Ventas en USD'],
          markers: {
            fillColors: ['#005bc5']
          }
        },
        dropShadow: {
          enabled: true,
          left: -3,
          blur: 2
        }
      },
      series: [
        {
          name: 'Ventas en USD',
          data: top10Vend.map((Zona) => ({
            x: Zona.Sector,
            y: ((Zona.Subtotal) - (Zona.Subtotal * 0.031)).toFixed(2),
          }))
        }
      ]
    })

    setLoading(false)
  }

  return (
    <>
      <Layout>
        <div className="container-fluid">
          {
            (loading) ?
              <>
                <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  <div className="loader-book">
                    <div>
                      <ul>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                        <li>
                          <svg fill="currentColor" viewBox="0 0 90 120">
                            <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                          </svg>
                        </li>
                      </ul>
                    </div>
                    <span>Cargando estadisticas...</span>
                  </div>
                </div>
              </> :
              <>
                {/* Div para movil */}
                <div className="text-center d-sm-block d-md-none">
                  <br />
                  <img src={notFoundImg} alt="..." onError={handleImageError} className="mx-auto w-100" />
                </div>

                {/* Div para web */}
                <div className='d-none d-md-block'>
                  <div className="container-fluid rounded">
                    <div className="row my-3">
                      <div className="col">
                        <span>
                          <h5><span className="badge rounded bg-primary">Inicio</span> <ChevronRight size={20} color="#000000" absoluteStrokeWidth /> <a href="/dashboard-gerente">Panel de control</a></h5>
                        </span>
                      </div>
                      <div className="col text-end text-belmeny">
                        <div className="row">
                          <div className="col offset-8">
                            <CircleUserRound size={36} color="#002975" />
                          </div>
                          <div className="col d-flex align-items-center justify-content-center">
                            <h4>{user.Nombre}</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Initial stats */}
                    <div className="row">
                      {/* Facturado */}
                      <div className="col-2 bg-white drop-shadow-card p-3 rounded">
                        <div className="ms-2 text-belmeny">
                          <h4>Ventas del mes:</h4>
                          <h6 className='fw-normal'>USD</h6>
                          <h5>Meta: ${formatNumber(totalMeta)}</h5>
                          <h5>Facturado: ${formatNumber(totalFacturado.toFixed(2))}</h5>
                        </div>
                      </div>
                      <div className="col bg-white drop-shadow-card p-3 rounded mx-3">
                        <h4>Facturas vencidas:</h4>
                        <div className="row">
                          <div className="col">
                            <h6 className='fw-normal'>USD</h6>
                            <h5 className="text-danger">{facturasVencidas.Vencidas_1_15_Dias} facturas - ${formatNumber(facturasVencidas.Vencidas_1_15_Dias_TotalNeto.toFixed(2))}</h5>
                            <h6 className='fw-normal'>1 - 15 días</h6>
                          </div>
                          <div className="col">
                            <h6 className='fw-normal'>USD</h6>
                            <h5 className="text-danger">{facturasVencidas.Vencidas_15_29_Dias} facturas - ${formatNumber(facturasVencidas.Vencidas_15_29_Dias_TotalNeto.toFixed(2))}</h5>
                            <h6 className='fw-normal'>16 - 30 días</h6>
                          </div>
                          <div className="col">
                            <h6 className='fw-normal'>USD</h6>
                            <h5 className="text-danger">{facturasVencidas.Vencidas_30_Dias} facturas - ${formatNumber(facturasVencidas.Vencidas_30_Dias_TotalNeto.toFixed(2))}</h5>
                            <h6 className='fw-normal'>Más de 30 días</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 bg-white drop-shadow-card p-3 rounded">
                        <h4>Facturas emitidas este mes:</h4>
                        <h5 className="text-belmeny">{facturasEmitidas} Facturas</h5>
                      </div>
                    </div>

                    {/* CHART VENTAS DEL AÑO EN CURSO */}
                    <div className="row my-4">
                      <div className='col bg-white rounded drop-shadow-card me-3'>
                        <div className="h-100 p-2">
                          <h4 className="text-center text-belmeny fst-italic">Ventas anuales</h4>
                          <Chart
                            options={ventasAnualesData.options}
                            series={ventasAnualesData.series}
                            type="area"
                            width="100%"
                            height="450px"
                          />
                        </div>
                      </div>
                      <div className="col-3 drop-shadow-card">
                        <ModulosGerente>
                          {(buttonId) => handleButtonClick(buttonId)}
                        </ModulosGerente>
                      </div>
                    </div>

                    {/* TOP CLIENTES & TOP PRODUCTOS */}
                    <div className="row text-center my-4">
                      <div className='col bg-white rounded drop-shadow-card me-3'>
                        <div className="row mt-2">
                          <div className="col">
                            <h4 className="ms-3 text-start fst-italic text-belmeny">Top clientes</h4>
                          </div>
                          <div className="col text-end">
                            <button className="btn btn-hover-details" onClick={(e) => handleButtonClick('topClientes')}>Ver detalles</button>
                          </div>
                        </div>
                        {
                          (topClientes.data.slice(0, 10).map((cliente, idx) =>
                            <div className="row my-2 px-3" key={idx}>
                              <div className="col text-start">
                                <h6 className='text-belmeny text-truncate' style={{ maxWidth: "450px" }}>{cliente.NombreCliente}</h6>
                                <h6 className='text-muted fw-normal fst-italic'>{cliente.NombreVendedor} - {cliente.Sector}</h6>
                              </div>
                              <div className="col-3 my-auto">
                                <h6 className='text-success'>{formatNumber(cliente.Facturado.toFixed(2))} USD</h6>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      <div className='col bg-white rounded drop-shadow-card me-3'>
                        <div className="row mt-2">
                          <div className="col">
                            <h4 className="ms-3 text-start fst-italic text-belmeny">Top productos</h4>
                          </div>
                          <div className="col text-end">
                            <button className="btn btn-hover-details" onClick={(e) => handleButtonClick('topProductos')}>Ver detalles</button>
                          </div>
                        </div>
                        <Chart
                          options={vxcChart.options}
                          series={vxcChart.series}
                          type="pie"
                          width="100%"
                          height="100%"
                        />
                      </div>
                      {/* FACTURADO Y COBRADO */}
                      <div className='col-3'>
                        <div className="row my-2">
                          <div className='col bg-white rounded drop-shadow-card me-3 p-2'>
                            <h5 className="">Facturado ayer</h5>
                            <h5 className="">{formatNumber((facturadoHoyVsAyer.hoy).toFixed(2))} USD</h5>
                            <span className={(facturadoHoyVsAyer.ayer < facturadoHoyVsAyer.hoy) ? "text-success" : "text-danger"}>
                              {(facturadoHoyVsAyer.ayer < facturadoHoyVsAyer.hoy) ? <TrendingUp /> : <TrendingDown />} {formatNumber((facturadoHoyVsAyer.hoy - facturadoHoyVsAyer.ayer).toFixed(2))} ({((facturadoHoyVsAyer.hoy - facturadoHoyVsAyer.ayer) / ((facturadoHoyVsAyer.hoy + facturadoHoyVsAyer.ayer / 2)) * 100).toFixed(2)}%)
                            </span> <br />
                            <span className="text-muted">vs antier</span>
                          </div>
                          <div className='col bg-white rounded drop-shadow-card me-3 p-2'>
                            <h5 className="">Facturado en 30 días</h5>
                            <h5 className="">{formatNumber((facturadoHoyVsAyer.hoy30Dias).toFixed(2))} USD</h5>
                            <span className={(facturadoHoyVsAyer.ayer30Dias < facturadoHoyVsAyer.hoy30Dias) ? "text-success" : "text-danger"}>
                              {(facturadoHoyVsAyer.ayer30Dias < facturadoHoyVsAyer.hoy30Dias) ? <TrendingUp /> : <TrendingDown />} {formatNumber((facturadoHoyVsAyer.hoy30Dias - facturadoHoyVsAyer.ayer30Dias).toFixed(2))} ({((facturadoHoyVsAyer.hoy30Dias - facturadoHoyVsAyer.ayer30Dias) / ((facturadoHoyVsAyer.hoy30Dias + facturadoHoyVsAyer.ayer30Dias / 2)) * 100).toFixed(2)}%)
                            </span> <br />
                            <span className="text-muted">vs últimos 30 días</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col bg-white rounded drop-shadow-card me-3 p-2'>
                            <h5 className="">Cobrado ayer</h5>
                            <h5 className="">{formatNumber((cobradoHoyVsAyer.hoy).toFixed(2))} USD</h5>
                            <span className={(cobradoHoyVsAyer.ayer < cobradoHoyVsAyer.hoy) ? "text-success" : "text-danger"}>
                              {(cobradoHoyVsAyer.ayer < cobradoHoyVsAyer.hoy) ? <TrendingUp /> : <TrendingDown />} {formatNumber((cobradoHoyVsAyer.hoy - cobradoHoyVsAyer.ayer).toFixed(2))} ({isNaN(((cobradoHoyVsAyer.hoy - cobradoHoyVsAyer.ayer) / ((cobradoHoyVsAyer.hoy + cobradoHoyVsAyer.ayer / 2)) * 100).toFixed(2)) ? 0.00 : ((cobradoHoyVsAyer.hoy - cobradoHoyVsAyer.ayer) / ((cobradoHoyVsAyer.hoy + cobradoHoyVsAyer.ayer / 2)) * 100).toFixed(2)}%)
                            </span> <br />
                            <span className="text-muted">vs antier</span>
                          </div>
                          <div className='col bg-white rounded drop-shadow-card me-3 p-2'>
                            <h5 className="">Cobrado en 30 días</h5>
                            <h5 className="">{formatNumber((cobradoHoyVsAyer.hoy30Dias).toFixed(2))} USD</h5>
                            <span className={(cobradoHoyVsAyer.ayer30Dias < cobradoHoyVsAyer.hoy30Dias) ? "text-success" : "text-danger"}>
                              {(cobradoHoyVsAyer.ayer30Dias < cobradoHoyVsAyer.hoy30Dias) ? <TrendingUp /> : <TrendingDown />} {formatNumber((cobradoHoyVsAyer.hoy30Dias - cobradoHoyVsAyer.ayer30Dias).toFixed(2))} ({((cobradoHoyVsAyer.hoy30Dias - cobradoHoyVsAyer.ayer30Dias) / ((cobradoHoyVsAyer.hoy30Dias + cobradoHoyVsAyer.ayer30Dias / 2)) * 100).toFixed(2)}%)
                            </span> <br />
                            <span className="text-muted">vs últimos 30 días</span>
                          
                      </div>
                    </div>
                  </div>
                  
                          <div className='mt-3 text-belmeny division' />
                            <div className="row">
                          
                            <div className="m-auto" style={{ width: "102%" }}>
                              <h5 className="text-center text-belmeny mt-4">Porcentaje de Top 100 Productos Vendido por Vendedor</h5>
                              <h5 className="text-center text-ingco">Promedio INGCO: {porcentajeGerente.porcen_ingco}%</h5>
                              <h5 className="text-center text-belmeny">Promedio VERT: {porcentajeGerente.porcen_vert}%</h5>
                              <Chart
                                options={porcentajeSupervisores.options}
                                series={porcentajeSupervisores.series}
                                type="bar"
                                width="100%"
                                height="400px"
                              />
                            
                            </div>
                            </div>
                        
                            <div className='mt-3 text-belmeny division' />
                            <div className="row">
                          
                            <div className="m-auto" style={{ width: "102%" }}>
                              <h5 className="text-center text-belmeny mt-4">Porcentaje de Atencidos y Desatendidos</h5>
                              <Chart
                                options={porcentajeSupervisores2.options}
                                series={porcentajeSupervisores2.series}
                                type="bar"
                                width="100%"
                                height="400px"
                              />
                            
                            </div>
                            </div>

                </div>
                </div>
                </div>
              </>
          }
        </div>
      </Layout>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>{modalTitle}</Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-4 bg-belmeny"></div>
        <Modal.Body>

          <div className="container-fluid">
            {/* Div para escritorio */}
            <div className='d-none d-md-block d-lg-block'>
              {
                (modalBody === 'pedidosPorDia') ?
                  <>
                    <div className="row my-4">
                      {
                        zonasSupervisor.map((Zona) =>
                          <div className='col'>
                            <button className='buttonMonths w-100' onClick={(e) => setZonaSelected(Zona.Sector)}><span className="text-white">{Zona.Sector}</span>
                            </button>
                          </div>)
                      }
                      <div className="col"><button className='buttonMonths w-100' onClick={(e) => setZonaSelected('General')}><span className="text-white">General</span></button></div>
                    </div>
                    <h5 className='text-belmeny my-2 ps-2'>Zona seleccionada: {zonaSelected}</h5>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className='mb-3'>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                        disabledDate={afterToday()}
                      />
                    </Stack>
                  </> : null
              }

              {
                (loading) ?
                  <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div className="loader-book">
                      <div>
                        <ul>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                      <span>Cargando {modalLoading}...</span>
                    </div>
                  </div> :
                  <>
                    {
                      (modalBody === 'pedidosPorDia') ?
                        <>
                          <PedidosPorDiaTable data={pedidosPorDia} resumenFacturado={resumenFacturado} resumenPedido={resumenPedido} />
                        </> : (modalBody === 'corteSemanal') ?
                          <>
                            <CorteSemanal zonasSupervisor={zonasSupervisor} ventasSemanales={ventasSemanales} ventasGralZona={ventasGralZona} />
                          </> : (modalBody === 'rankingVendedores') ?
                            <>
                              <RankingVendedores top10Chart={top10Chart} />
                            </> : (modalBody === 'cobranzasZona') ?
                              <>
                                <CobranzasZona fpapData={fpapData} />
                              </> : (modalBody === 'topClientes') ?
                                <>
                                  <TopClientes topClientes={topClientes} />
                                </> : (modalBody === 'topProductos') ?
                                  <>
                                    <TopProductos topProductos={ventasProductos} />
                                  </> : null
                    }
                  </>
              }
            </div>

            {/* Div para móvil */}
            <div className='d-sm-block d-md-none'>
              {
                (loading) ?
                  <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div className="loader-book">
                      <div>
                        <ul>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                      <span>Cargando {modalLoading}...</span>
                    </div>
                  </div> :
                  <>
                    {
                      (modalBody === 'pedidosPorDia') ?
                        <>
                          <PedidosPorDiaTable data={pedidosPorDia} resumenFacturado={resumenFacturado} resumenPedido={resumenPedido} />
                        </> : (modalBody === 'corteSemanal') ?
                          <>
                            <CorteSemanal zonasSupervisor={zonasSupervisor} ventasSemanales={ventasSemanales} ventasGralZona={ventasGralZona} />
                          </> : (modalBody === 'rankingVendedores') ?
                            <>
                              <RankingVendedores top10Chart={top10Chart} />
                            </> : (modalBody === 'cobranzasZona') ?
                              <>
                                <CobranzasZona fpapData={fpapData} />
                              </> : (modalBody === 'topClientes') ?
                                <>
                                  <TopClientes topClientes={topClientes} />
                                </> : (modalBody === 'topProductos') ?
                                  <>
                                    <TopProductos topProductos={ventasProductos} />
                                  </> : null
                    }
                  </>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}